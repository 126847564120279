import _ from 'lodash'

function addStateTypenamesToObject(object, pathToWalkAddingTypenames) {
  _.reduce(
    _.toPath(pathToWalkAddingTypenames),
    (partialPath, pathComponent) => {
      partialPath += `.${pathComponent}`
      const typename = _.upperFirst(_.camelCase(partialPath))
      _.set(object, `${partialPath.slice(1)}.__typename`, `${typename}State`)
      return partialPath
    },
    ''
  )
}

export function updateStateCache(client, basePath, ...keyValuePairs) {
  const [keys, values] = _.zip(...keyValuePairs)
  const zipObjectPaths = _.map(keys, (k) => `${basePath}.${k}`)
  let data = _.zipObjectDeep(zipObjectPaths, values)
  addStateTypenamesToObject(data, basePath)
  client.writeData({ data })
}
