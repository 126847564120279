import toast from '../utils/toast';

export default function validatePassword(password, passwordConfirmation) {
  const specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (password.length < 8) {
    toast('A senha precisa de ao menos 8 caracteres', 'error');
    return false;
  }

  if (!password.match(specialCharacters)) {
    toast('A senha precisa conter pelo menos 1 caracter especial', 'error');
    return false;
  }

  if (!password.match(/[A-Z]/)) {
    toast('A senha precisa conter pelo menos 1 caracter maiúsculo', 'error');
    return false;
  }

  if (!password.match(/[a-z]/)) {
    toast('A senha precisa conter pelo menos 1 caracter minúsculo', 'error');
    return false;
  }

  if (!password.match(/\d/)) {
    toast('A senha precisa conter pelo menos 1 número', 'error');
    return false;
  }

  if (password !== passwordConfirmation) {
    toast('As senhas precisam ser iguais', 'error');
    return false;
  }

  return true;
}
