export default {
  '/cobertura': 'coverage',
  '/analises-temporais': 'temporal_analysis',
  '/desmatamento': 'deforestation',
  '/regeneracao': 'regeneration',
  '/irrigacao': 'irrigation',
  '/infraestrutura': 'infrastructure',
  '/pastagem': 'quality_of_pasture_data',
  '/fogo': 'fire',
  '/mineracao': 'mining',
  '/qualidade': 'coverage_quality',
  '/solo': 'soil',
  '/cobertura_10m': 'sentinel',
  '/degradacao': 'degradation',
  '/urbano': 'urban',
};
