export default function resetToken() {
  localStorage.removeItem('tokenId');
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('email');
  localStorage.removeItem('profile');
  localStorage.removeItem('institutionId');
  localStorage.removeItem('institutionName');
  localStorage.removeItem('notify_user_new_term_of_use_version');
  localStorage.removeItem('unseen_alerts');
}
