import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import styles from './CoordinatesInfo.module.scss';

export default function CoordinatesInfo({
  headerIsVisible,
  mouseCoordinates,
}) {
  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithoutHeader]: !headerIsVisible,
    }) }>
      <div className={ styles.controlWrapper }>
        <span className={ styles.mouseCoordinatesInfo }>{ mouseCoordinates || '0, 0' }</span>
      </div>
    </div>
  );
}
