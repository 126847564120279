import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    toast @client {
      content
      severity
      visible
    }
  }
`;
