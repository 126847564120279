import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import { API_URLS } from '../../data/constants';

const cache = new InMemoryCache();

const link = new HttpLink({
  uri: API_URLS['8.0']
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
