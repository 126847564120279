import React, { useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import toast from '../../../../../../../../utils/toast';

import styles from './PasswordRecoveryForm.module.scss';

const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;

const initialState = {
  email: '',
};

export default function PasswordRecoveryForm({
  onCancel = () => {},
}) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);
  const [showInfo, setShowInfo] = useState(false);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      email,
    } = state;

    const payload = { email };

    fetch(`${ API_URL }/v1/users/send_recovery_instructions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        if (_.get(data, 'success')) {
          handleStateChange('email', '');
          toast(formatMessage('mapbiomas.toast.email_sent'), 'success');
        } else {
          toast(_.get(data, 'errors[0]') || formatMessage('mapbiomas.toast.error'), 'error');
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const renderInfo = () => {
    return (
      <p className={ styles.successText }><FormattedMessage id="mapbiomas.user.password_recovery.instructions" /></p>
    );
  }

  const renderForm = () => {
    return (
      <form onSubmit={ handleSubmit }>
        <FormControl className={ styles.formControl }>
          <TextField
            id="email-field"
            label={ formatMessage('mapbiomas.user.password_recovery.fields.email') }
            variant="outlined"
            type="email"
            className={ styles.filterFormInput }
            value={ state.email }
            onChange={ handleTextChange.bind(this, 'email') }
            required
          />
        </FormControl>
        <div className={ styles.actionsWrapper }>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={ onCancel }
          >
            <FormattedMessage id="mapbiomas.user.password_recovery.buttons.cancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
          >
            <FormattedMessage id="mapbiomas.user.password_recovery.buttons.confirm" />
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div className={ styles.formWrapper }>
      <h3 className={ styles.title }>
        <FormattedMessage id="mapbiomas.user.password_recovery.title" />
      </h3>
      { showInfo && renderInfo() }
      { !showInfo && renderForm() }
    </div>
  )
}
