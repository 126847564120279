import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  query GetBaseData {
    classesLevelsList {
      id
      color
      positionInTree
      level
      parentId
      defaultName
    }
  }
`;
