import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './TransitionClasses.module.scss';

import {
  GET_BASE_DATA,
  GET_TERRITORIES_LIST,
} from './query';

// non_vegetated_areas_to_vegetated,
// increase_of_water_surface,
// reduction_of_water_surface,
// gain_of_forest_plantations,
// vegetated_areas_to_non_vegetated

const transitionClassesData = [
  {
    id: 'non_vegetated_areas_to_vegetated',
    pixelValue: 1,
    label: 'Transições de classes de agropecuária ou áreas não vegetadas para cobertura florestal ou áreas naturais não florestais.',
    color: '#06FF03'
  },
  {
    id: 'increase_of_water_surface',
    pixelValue: 2,
    label: 'Transições que acrescem superfície de água',
    color: '#3FACFF'
  },
  {
    id: 'reduction_of_water_surface',
    pixelValue: 3,
    label: 'Transições que reduzem superfície de água',
    color: '#FFA505'
  },
  {
    id: 'gain_of_forest_plantations',
    pixelValue: 4,
    label: 'Transições com ganho de áreas de silvicultura.',
    color: '#8A2BE2'
  },
  {
    id: 'vegetated_areas_to_non_vegetated',
    pixelValue: 5,
    label: 'Transições de cobertura florestal ou áreas naturais não florestais para agropecuária ou áreas não vegetadas.',
    color: '#FF0004'
  },
  {
    id: 'areas_without_transitions_or_unobserved',
    pixelValue: 6,
    label: 'Áreas sem transição ou transições que envolvem áreas não observadas ou transições entre classes dentro do nivel 1 de legenda.',
    color: '#AAA9A9'
  },
];

export default function TransitionClasses({
  onItemsChange = () => {},
}) {
  const formatMessage = useFormatMessage();
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const activeItems = _.get(baseData, 'app.baseParams.activeClassTreeNodeIds') || [];
  const allClassesActive = _.size(activeItems) === _.size(transitionClassesData);

  const handleItemsChange = (ids) => {
    onItemsChange({ activeClassTreeNodeIds: ids });
  };

  useEffect(() => {
    handleItemsChange(_.map(transitionClassesData, 'pixelValue'));
  }, []);

  const itemIsActive = (id) => {
    return _.includes(activeItems, id);
  };

  const toggleActiveItem = (id) => {
    if (itemIsActive(id)) {
      let itemsClone = _.clone(activeItems);
      itemsClone = _.filter(itemsClone, (itemId) => itemId !== id);

      handleItemsChange(itemsClone);
    } else {
      handleItemsChange([
        ...activeItems,
        id
      ]);
    }
  };

  const toggleAllItems = () => {
    if (allClassesActive) {
      handleItemsChange([]);
    } else {
      handleItemsChange(_.map(transitionClassesData, 'pixelValue'));
    }
  };

  return (
    <div>
      <h2 className={ styles.title }><FormattedMessage id={ `mapbiomas.header.classes_control.title` } /></h2>
      <FormControlLabel
        classes={{
          label: styles.checkboxLabel
        }}
        control={
          <Checkbox
            checked={ allClassesActive }
            onChange={ toggleAllItems }
            name="allClasses"
            color="primary"
            size="small"
          />
        }
        label={ formatMessage('mapbiomas.header.classes_control.toggle_all') }
      />
      <ul className={ styles.classList }>
        { _.map(transitionClassesData, (item) => {
          return (
            <li
              key={ `class-item-level-${ item.pixelValue }` }
              className={ styles.classListItemBase } 
            >
              <div className={ styles.classListItemHeader }>
                <IconButton
                  className={ styles.classListItemCheckControl }
                  style={{ color: item.color }}
                  onClick={ toggleActiveItem.bind(this, item.pixelValue) }
                >
                  { itemIsActive(item.pixelValue) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
                </IconButton>
                <span className={ styles.classListItemTitle }>
                  <FormattedMessage id={ `mapbiomas.header.transition_classes.${ item.id }` } />
                </span>
              </div>
            </li>
          );
        }) }
      </ul>
    </div>
  );
}
