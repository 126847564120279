import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import numbro from 'numbro';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PieChart from '../../components/PieChart';
import LineChart from '../../components/LineChart';

import { GET_BASE_DATA } from './query';

import styles from './DashboardDialogQualityContent.module.scss';

const formatNumber = (value) => numbro(value).format({
  thousandSeparated: true,
  mantissa: 2
});

const sampleData = {
  categories: ['1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
  series: [
    {
      areaStyle: {},
      name: 'Floresta',
      data: [595674939.89, 595858208.39, 592223534.09, 587669613.43, 584412308.18, 582285405.14, 580003887.87, 577174530.25, 573872548.39, 570535582.59, 566986828.49, 563791577.33, 560581814.33, 558036261.05, 554711478.22, 551899400.22, 549925638.40, 547103441.21, 542411487.15, 539024545.57, 536274323.86, 534123915.16, 532939096.89, 531903157.80, 531258183.03, 531330861.09, 530767363.33, 530151910.14, 529092772.60, 527471134.82, 526084434.15, 524394580.56, 522312764.17, 518937915.7],
    },
    {
      areaStyle: {},
      name: 'Formação Natural não Florestal',
      data: [59459326.34, 59179131.53, 58436262.78, 57959280.14, 57371957.47, 56949802.89, 56693940.37, 56236429.20, 56349333.57, 56243071.31, 56114923.96, 55743441.27, 55632730.39, 55659695.87, 55774630.94, 55860585.60, 56000764.22, 55731141.96, 55570984.87, 54937312.80, 54642890.35, 54490380.13, 54278787.66, 53953672.12, 53544233.85, 53133646.89, 52758293.99, 52463272.89, 52031282.44, 51728929.54, 51210931.00, 50766763.92, 50606236.86, 50036334.3],
    },
    {
      areaStyle: {},
      name: 'Agropecuária',
      data: [177441572.66, 177009733.00, 182071397.87, 187016746.02, 190697616.49, 193022492.96, 195601038.43, 199090181.74, 202013914.92, 205463203.72, 209432383.89, 212795934.74, 215986009.67, 218852381.32, 221452402.41, 223879197.86, 225756355.93, 229024368.38, 233452071.06, 237136160.36, 240131790.74, 242291337.07, 243553185.67, 244705951.05, 245539736.32, 246295866.24, 247007504.61, 247980862.10, 249293550.81, 251012446.09, 252782607.65, 255149573.21, 257114716.61, 260671891.18],
    },
    {
      areaStyle: {},
      name: 'Área não Vegetada',
      data: [3342355.08, 3216005.90, 3090312.77, 3106092.11, 3058678.31, 3060490.55, 3026351.43, 3049558.73, 3175548.75, 3140836.06, 3128865.31, 3134419.50, 3123874.58, 3183666.99, 3273163.95, 3394125.47, 3513908.78, 3520347.73, 3545410.84, 3518871.84, 3522706.66, 3556286.07, 3583335.56, 3631760.05, 3669189.27, 3726614.12, 3784220.84, 3845488.16, 4019087.81, 4083616.40, 4212398.62, 4338027.67, 4557028.78, 4877005.18],
    },
    {
      areaStyle: {},
      name: 'Corpo D\'água',
      data: [15630653.94, 16286108.19, 15727862.32, 15797880.51, 16009310.45, 16231926.72, 16225103.89, 15999769.58, 16139201.80, 16167840.49, 15887530.26, 16084888.32, 16224919.41, 15817499.31, 16337881.14, 16516505.25, 16353296.41, 16170516.25, 16569834.75, 16932916.26, 16978019.62, 17087793.62, 17195309.71, 17355219.82, 17538258.83, 17062605.45, 17232261.96, 17108171.33, 17112991.26, 17253461.42, 17259018.79, 16900270.66, 16958455.32, 17026435.31],
    },
    {
      areaStyle: {},
      name: 'Não observado',
      data: [26001.45, 25662.35, 25479.53, 25237.14, 24978.46, 24731.09, 24527.38, 24379.86, 24301.93, 24315.19, 24317.45, 24588.21, 25500.97, 25344.83, 25292.71, 25034.96, 24885.62, 25033.82, 25060.70, 25042.53, 25118.14, 25137.31, 25133.88, 25088.53, 25248.06, 25255.58, 25204.65, 25144.75, 25164.45, 25261.08, 25459.14, 25633.35, 25647.61, 25267.58],
    },
  ]
};

export default function DashboardDialogQualityContent({
  activeYear,
  baseParams,
}) {
  const territory = _.get(baseParams, 'territory');
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryId: territory
    },
    skip: !territory
  });

  const renderLoading = () => {
    return (
      <div className={ styles.loadingWrapper }>
        <CircularProgress />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={ styles.content }>
        <div className={ styles.chartsWrapper }>
          <div>
            <h3>Visualização por classe</h3>
            <PieChart
              colors={ ['#FFFF1C', '#A0E260', '#FFD11A', '#FF1919'] }
              serie={ {
                title: 'Total',
                data: [
                  { name: 'Entre 4 e 6 observações', value: 36.98 },
                  { name: 'Acima de 6 observações', value: 35.33 },
                  { name: 'Entre 1 e 3 observações', value: 24.8 },
                  { name: 'Nenhuma observação', value: 2.89 },
                ]
              } }
            />
          </div>
          <div>
            <h3>Série histórica</h3>
            <LineChart
              area
              categories={ sampleData.categories }
              series={ sampleData.series }
            />
          </div>
        </div>
        <div className={ styles.dataTableWrapper }>
          <div className={ styles.tableHeader }>
            <h3 className={ styles.tableTitle }>Tabela de dados</h3>
            {/* <Button
              color="secondary"
              size="small"
              variant="contained"
              startIcon={ <CloudDownloadIcon /> }
            >
              Download
            </Button> */}
          </div>
          <div className={ styles.tableWrapper }>
            <Table size="small" className={ styles.table }>
              <TableHead>
                <TableRow>
                  <TableCell>Classes</TableCell>
                  { _.map(sampleData.categories, (year) => {
                    return (
                      <TableCell key={ `table-header-cell-${ year }` } align="right">{ year }</TableCell>
                    );
                  }) }
                </TableRow>
              </TableHead>
              <TableBody>
                { _.map(sampleData.series, (serie, index) => {
                  return (
                    <TableRow key={ `table-row-serie-${ index }` }>
                      <TableCell component="th" scope="row">{ serie.name }</TableCell>
                      { _.map(serie.data, (value, cellIndex) => {
                        return (
                          <TableCell key={ `table-row-${ index }-cell-${ cellIndex }` } align="right">{ value ? formatNumber(value) : '-' }</TableCell>
                        );
                      }) }
                    </TableRow>
                  );
                }) }
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  };

  if (loadingBaseData) {
    return renderLoading();
  } else if (!loadingBaseData && territory) {
    return renderContent();
  } else {
    return null;
  }
}
