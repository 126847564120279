import { createMuiTheme } from '@material-ui/core/styles';

import {
  greys,
  primaryColor,
  primaryColorLight,
  primaryColorDark,
  primaryColorContrastText,
  secondaryColor,
  secondaryColorLight,
  secondaryColorDark,
  secondaryColorContrastText
} from './variables';

export default createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiFormControl: {
      fullWidth: true
    },
    MuiInputLabel: {
      shrink: true
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiSelect: {
      disableUnderline: true
    }
  },

  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '12px 14px'
      },
      notchedOutline: {
        borderColor: 'transparent !important',
      },
    },
    MuiAutocomplete: {
      root: {
        '&.autocomplete-light': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5) !important'
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            color: 'white'
          },
          '& .MuiInputLabel-shrink': {
            paddingLeft: 0,
            color: 'white',
            backgroundColor: '#404040'
          },
          '& .MuiIconButton-root': {
            color: 'white',
          }
        }
      },
      inputRoot: {
        padding: 0,
      },
      noOptions: {
        fontSize: 14
      },
      option: {
        fontSize: 14
      },
      popper: {
        zIndex: 9999
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
      contained: {
        paddingBottom: '5px',
        paddingTop: '5px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none'
        },
        '&:focus': {
          boxShadow: 'none'
        }
      },
      containedSizeLarge: {
        paddingBottom: '6px',
        paddingTop: '6px',
        paddingLeft: '25px',
        paddingRight: '25px'
      },
      containedSizeSmall: {
        paddingBottom: '4px',
        paddingTop: '4px',
        paddingLeft: '15px',
        paddingRight: '15px'
      },
      startIcon: {
        marginRight: '16px'
      },
      text: {
        padding: '6px 16px'
      }
    },
    MuiDialog: {
      root: {
        zIndex: '5000 !important'
      }
    },
    MuiPopover: {
      root: {
        zIndex: '5000 !important'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiFormControl: {
      root: {
        '&.input-label-gray': {
          '& .MuiInputLabel-shrink': {
            backgroundColor: '#F7F7F7'
          },
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#555',
        fontWeight: 400
      },
      shrink: {
        padding: '0 6px',
        backgroundColor: 'white'
      },
      outlined: {
        transform: 'translate(8px, -18px) scale(0.75) !important',
        background: 'transparent !important',
        color: '#8E8E8E !important',
      }
    },
    MuiInput: {
      root: {
      },
      formControl: {
        'label + &': {
          marginTop: 20
        }
      }
    },
    MuiInputBase: {
      root: {
        background: '#F5F5F5'
      },
      input: {
        padding: 0,
        fontSize: 14
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 40,
        fontSize: 14
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontWeight: 400,
        backgroundColor: '#656565',
      },
      tooltipArrow: {
        color: '#656565',
      },
      popper: {
        zIndex: 9999
      }
    },
    MuiFormLabel: {
      root: {
        // marginLeft: 10
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: '#F7F7F7',
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '0',
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0 12px',
        minHeight: 35,
        '&.Mui-expanded': {
          minHeight: 35,
          backgroundColor: '#F5F5F5'
        }
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0',
        }
      },
      expandIcon: {
        padding: 4,
        '& svg': {
          fontSize: 24
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
      }
    },
    MuiSnackbar: {
      root: {
        zIndex: 9999
      }
    },
    MuiFormControlLabel: {
      root: {
        color: '#8E8E8E',
      },
      label: {
        fontWeight: 400
      }
    },
  },

  palette: {
    background: {
      default: greys[100]
    },
    grey: greys,
    primary: {
      dark: primaryColorDark,
      light: primaryColorLight,
      main: primaryColor,
      contrastText: primaryColorContrastText
    },
    secondary: {
      dark: secondaryColorDark,
      light: secondaryColorLight,
      main: secondaryColor,
      contrastText: secondaryColorContrastText
    },
    text: {
      primary: greys[800],
      secondary: greys[500]
    }
  },

  typography: {
    fontFamily: '"Inter", "Open-Sans", sans-serif'
  }
});
