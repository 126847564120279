import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import MapForm from '../MapForm';

import styles from './MapEditDialog.module.scss';

export default function MapEditDialog({
  isOpen,
  data,
  onClose = () => {},
  onSubmit = () => {},
}) {
  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      classes={ {
        paper: styles.dialogWrapper
      } }
    >
      <header className={ styles.header }>
        <h2 className={ styles.title }>
          <FormattedMessage id="mapbiomas.user.my_maps.forms.edit_map.title" />
        </h2>
        <IconButton className={ styles.closeButton } onClick={ onClose }>
          <CloseIcon />
        </IconButton>
      </header>
      <div className={ styles.content }>
        <MapForm
          editMode
          data={ data }
          onCancel={ onClose }
          onSubmit={ onSubmit }
        />
      </div>
    </Dialog>
  );
}
