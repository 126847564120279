import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';

import { useIntl } from 'react-intl';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import { GET_BASE_DATA } from './query';

import styles from './YearTimelineMultiple.module.scss';

const useStyles = makeStyles((theme) => ({
  headerInfoValue: {
    color: theme.palette.primary.main,
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '4px',
    fontSize: 12,
    color: '#F5F5F9',
    backgroundColor: '#5A5A5A',
  },
  arrow: {
    color: '#5A5A5A',
  },
  popper: {
    zIndex: 4000,
  },
}))(Tooltip);

const intervalsByModule = {
  temporal_analysis: [
    [1985, 1990],
    [1990, 1995],
    [1995, 2000],
    [2000, 2005],
    [2005, 2010],
    [2010, 2015],
    [2015, 2020],
    [1990, 2000],
    [2000, 2010],
    [2010, 2020],
    [1985, 2000],
    [1992, 2002],
    [2002, 2012],
    [1985, 2023],
    [2000, 2023],
    [2012, 2023],
  ],
  deforestation: [
    [1986, 1986],
    [1986, 1987],
    [1986, 1988],
    [1986, 1989],
    [1986, 1990],
    [1986, 1991],
    [1986, 1992],
    [1986, 1993],
    [1986, 1994],
    [1986, 1995],
    [1986, 1996],
    [1986, 1997],
    [1986, 1998],
    [1986, 1999],
    [1986, 2000],
    [1986, 2001],
    [1986, 2002],
    [1986, 2003],
    [1986, 2004],
    [1986, 2005],
    [1986, 2006],
    [1986, 2007],
    [1986, 2008],
    [1986, 2009],
    [1986, 2010],
    [1986, 2011],
    [1986, 2012],
    [1986, 2013],
    [1986, 2014],
    [1986, 2015],
    [1986, 2016],
    [1986, 2017],
    [1986, 2018],
    [1986, 2019],
    [1986, 2020],
    [1986, 2021],
    [1986, 2022],
    [1986, 2023],
    [1987, 2023],
    [1988, 2023],
    [1989, 2023],
    [1990, 2023],
    [1991, 2023],
    [1992, 2023],
    [1993, 2023],
    [1994, 2023],
    [1995, 2023],
    [1996, 2023],
    [1997, 2023],
    [1998, 2023],
    [1999, 2023],
    [2000, 2023],
    [2001, 2023],
    [2002, 2023],
    [2003, 2023],
    [2004, 2023],
    [2005, 2023],
    [2006, 2023],
    [2007, 2023],
    [2008, 2023],
    [2009, 2023],
    [2010, 2023],
    [2011, 2023],
    [2012, 2023],
    [2013, 2023],
    [2014, 2023],
    [2015, 2023],
    [2016, 2023],
    [2017, 2023],
    [2018, 2023],
    [2019, 2023],
    [2020, 2023],
    [2021, 2023],
    [2022, 2023],
    [2023, 2023],
  ],
};

export default function YearTimelineMultiple({
  activeModule,
  headerIsVisible,
  value: initialValue,
  onChange = () => {},
}) {
  const intl = useIntl();
  const intervals = intervalsByModule[activeModule];

  const yearList = _.sortBy(_.uniq(_.flatten(intervals)));
  const startYearsList = _.sortBy(
    _.uniq(_.map(intervals, (range) => _.first(range)))
  );

  const classes = useStyles();
  const { data: baseData } = useQuery(GET_BASE_DATA);

  const [startValue, setStartValue] = useState(
    _.get(intervals, '[0][0]', 1985)
  );
  const [endValue, setEndValue] = useState(
    _.get(_.last(intervals), '[1]', 2023)
  );

  const filteredIntervals = _.filter(
    intervals,
    (range) => _.first(range) === startValue
  );
  const endYearsList = _.sortBy(
    _.uniq(_.map(filteredIntervals, (range) => _.last(range)))
  );

  // useEffect(() => setCurrentValue(initialValue), [initialValue]);
  // useEffect(() => setUpdateKey(_.uniqueId('timeline-')), [isRange, _.last(rangeLimits), _.last(rangeSpecificValues)]);

  const handleChange = (type, _event, newValue) => {
    if (type === 'start') {
      const filteredIntervals = _.filter(
        intervals,
        (range) => _.first(range) === newValue
      );
      const endYearsList = _.sortBy(
        _.uniq(_.map(filteredIntervals, (range) => _.last(range)))
      );
      setStartValue(newValue);
      setEndValue(_.last(endYearsList));
    } else {
      setEndValue(newValue);
    }
  };

  const handleChangeComitted = (type, _event, newValue) => {
    if (type === 'start') {
      const filteredIntervals = _.filter(
        intervals,
        (range) => _.first(range) === newValue
      );
      const endYearsList = _.sortBy(
        _.uniq(_.map(filteredIntervals, (range) => _.last(range)))
      );
      onChange([newValue, _.last(endYearsList)]);
    } else {
      onChange([startValue, newValue]);
    }
  };

  function ValueLabelComponent(props) {
    const { children, index, open, value } = props;

    let isOpen = open;
    let title = value;

    if (index === 1) {
      isOpen = true;
      title = `${value} - ${intl.formatMessage({
        id: 'mapbiomas.timeline.selected_value',
      })}`;
    }

    return (
      <CustomTooltip arrow open={isOpen} placement='top' title={title}>
        {children}
      </CustomTooltip>
    );
  }

  let sliderItemsProps = {
    step: null,
    min: _.first(yearList),
    max: _.last(yearList),
  };

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperWithHeader]: headerIsVisible,
      })}
      id='year-timeline'
    >
      <div className={styles.timelineInfo}>
        <DateRangeIcon />
      </div>
      <div className={styles.sliderWrapper}>
        <Slider
          {...sliderItemsProps}
          marks={startYearsList.map((rsv) => ({ value: rsv }))}
          value={startValue}
          track={false}
          valueLabelDisplay='auto'
          ValueLabelComponent={ValueLabelComponent}
          onChange={handleChange.bind(this, 'start')}
          onChangeCommitted={handleChangeComitted.bind(this, 'start')}
          classes={{
            mark: styles.timelineMark,
          }}
        />
        <span className={classnames(styles.valueText, classes.headerInfoValue)}>
          {startValue} - {endValue}
        </span>
        <Slider
          {...sliderItemsProps}
          marks={endYearsList.map((rsv) => ({ value: rsv }))}
          value={endValue}
          track={false}
          valueLabelDisplay='auto'
          ValueLabelComponent={ValueLabelComponent}
          onChange={handleChange.bind(this, 'end')}
          onChangeCommitted={handleChangeComitted.bind(this, 'end')}
          classes={{
            mark: styles.timelineMark,
          }}
        />
      </div>
    </div>
  );
}
