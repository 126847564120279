import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CSVLink } from 'react-csv'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import ClassificationsChart from '../../components/ClassificationsChart'
import StickyTable from '../../components/StickyTable'
import { onCurrentLocaleCustom } from '../../../../../../../../utils/locale'
import { GET_BASE_DATA, GET_CATEGORIES_OF_OBJECT_TREE_NODES } from './query'
import styles from './DashboardDialogGenericClassesContent.module.scss'

const getYearRangeFromClassTreeNodes = _.flow(
  _.partial(_.flatMap, _, 'classTreeNode.coverageClassificationDataByTreeList'),
  _.partial(_.map, _, _.flow(_.property('year'), _.method('slice', 1), Number)),
  _.uniq,
  _.sortBy
)

const useStyles = makeStyles((theme) => ({
  printButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
}));

export default function DashboardDialogGenericClassesContent({
  clientType,
  activeYear,
  baseParams,
  hasObjectFilter = false
}) {
  const classes = useStyles();
  const activeSubmodule = _.get(baseParams, 'activeSubmodule')
  const territories = _.get(baseParams, 'territories')
  const territoryIds = _.map(territories, 'id')
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds')
  const activeClassTreeOptionValue = _.get(baseParams, 'activeClassTreeOptionValue')
  const activeObjectTreeNodeIds = _.get(baseParams, 'activeObjectTreeNodeIds')
  const bufferId = _.get(baseParams, 'buffer')
  const objectTreeNodesData = useQuery(
    GET_CATEGORIES_OF_OBJECT_TREE_NODES, {
      variables: { objectTreeNodeIds: activeObjectTreeNodeIds },
      skip: !hasObjectFilter
    }
  )
  const activeObjectCategoryIds = _.map(_.get(objectTreeNodesData, 'data.geospatialObjectCategoryTreeNodesList'), 'categoryId')

  if (!activeObjectCategoryIds) {
    return null
  }

  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryIds,
      activeClassTreeNodeIds,
      hasObjectFilter,
      activeObjectCategoryIds,
      bufferId,
      classTreeKey: activeClassTreeOptionValue,
    },
    skip: _.isEmpty(territoryIds) || !activeClassTreeOptionValue
  })

  const classTree = _.get(baseData, 'classTreeByKey')
  const allClassTreeLevels = _.get(classTree, 'allClassTreeLevelsList')
  const classTreeLevels = _.get(classTree, 'mvClassTreeLevelsUi')

  if (hasObjectFilter && classTreeLevels) {
    _.each(_.concat(allClassTreeLevels, classTreeLevels), (ctl) => {
      _.set(
        ctl,
        'classTreeNode.coverageClassificationDataByTree',
        _.get(ctl, 'classTreeNode.coverageClassificationDataByTreeListWithObjectIntersection')
      )
    })
  }

  const years = getYearRangeFromClassTreeNodes(classTreeLevels)
  const classTreeCharts = _.get(classTree, 'classTreeCharts')
  const tableRows = _.map(classTreeLevels, (treeNode) => ({
    name: `${treeNode.positionInTree.join('. ')}. ${onCurrentLocaleCustom(treeNode.i18nStrings)}`,
    padding: treeNode.level * 12,
    data: _.mapKeys(
      _.mapValues(
        _.groupBy(treeNode.classTreeNode.coverageClassificationDataByTreeList, 'year'),
        '[0].areaInHa'
      ),
      (_value, year) => _.trimStart(year, '_')
    )
  }))

  const renderLoading = () => {
    return (
      <div className={ styles.loadingWrapper }>
        <CircularProgress />
      </div>
    )
  }

  const renderDownloadTableLink = () => {
    if (_.isEmpty(years) || _.isEmpty(tableRows)) {
      return null;
    }

    let headers = [
      { key: 'class', label: 'Classe' },
    ];

    _.each(years, (year) => {
      headers.push({ key: _.toString(year), label: _.toString(year) });
    });

    const parsedData = _(tableRows)
      .map((serie) => {
        let rowData = { class: _.get(serie, 'name') };

        _.each(_.get(serie, 'data'), (value, year) => {
          rowData[_.toString(year)] = value;
        });

        return rowData;
      })
      .flatten()
      .value();

    return (
      <CSVLink
        data={ parsedData }
        headers={ headers }
        className={ classnames(styles.csvDownloadLink, classes.printButton) }
        filename="MapBiomas - Tabela de Dados.csv"
      >
        <CloudDownloadIcon />
        <span>Download</span>
      </CSVLink>
    );
  };

  const renderContent = () => {
    let parsedClassTreeCharts = _.cloneDeep(classTreeCharts);

    // TODO: Find a way to update 'fire_annual' chart type on database to load from 'classTreeChartsList'
    if (activeSubmodule === 'fire_annual') {
      _.set(parsedClassTreeCharts, '[0].coverageClassificationChartType.key', 'stacked_bar');
    }

    return (
      <div className={ styles.content }>
        <div className={ styles.chartsWrapper }>
          <ClassificationsChart
            // Add key using 'activeSubmodule' to prevent mixed chart types bug on 'fire' module
            key={ activeSubmodule }
            allClassTreeLevels={ allClassTreeLevels }
            activeClassTreeLevels={ classTreeLevels }
            classTreeCharts={ parsedClassTreeCharts }
            years={ years }
            activeYear={ activeYear }
          />
          { /* _.map(chartTypes, renderChart) */ }
        </div>
        <div className={ styles.dataTableWrapper }>
          <div className={ styles.tableHeader }>
            <h3 className={ styles.tableTitle }>
              <b><FormattedMessage id="mapbiomas.dashboard.generic.data_table.title" /></b>
              <span><FormattedMessage id="mapbiomas.dashboard.generic.data_table.subtitle" /></span>
            </h3>
            { renderDownloadTableLink() }
          </div>
          <StickyTable
            columns={ years }
            rows={ tableRows }
          />
        </div>
      </div>
    )
  }

  const hasDataByTree = _.some(
    allClassTreeLevels,
    (t) => _.size(_.get(t, 'classTreeNode.coverageClassificationDataByTree')) > 0
  )

  const hasDataByTreeIntersectedByObject = _.some(
    allClassTreeLevels,
    (t) => _.size(_.get(t, 'classTreeNode.coverageClassificationDataByTreeListWithObjectIntersection')) > 0
  )

  if (loadingBaseData) {
    return renderLoading()
  } else if (!hasDataByTree && !hasDataByTreeIntersectedByObject) {
    return <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h3 className={ styles.tableTitle }>Ainda não temos estatísticas para as categorias selecionadas.</h3>
    </div>
  } else if (!loadingBaseData && territories) {
    return renderContent()
  } else {
    return null
  }
}
