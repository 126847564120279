export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default function parseToken(token, apiToken) {
  const parsedToken = parseJwt(token);

  localStorage.setItem('token', token);
  localStorage.setItem('tokenAPI', apiToken);
  localStorage.setItem('sub', parsedToken.sub);
  localStorage.setItem('name', parsedToken.name);
  localStorage.setItem('email', parsedToken.email);
}
