import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Cleave from 'cleave.js/react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './CoordinatesBox.module.scss';

const useStyles = makeStyles((theme) => ({
  searchButtonActive: {
    backgroundColor: '#F1F1F1 !important',
    '& svg': {
      fill: `${ theme.palette.primary.main } !important`,
    }
  }
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '2px 12px',
    fontSize: 12,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
    '& a': {
      color: '#ffc65d',
    },
    '& ul': {
      paddingLeft: 24,
    },
    '& ul li': {
      listStyleType: 'disc'
    },
  },
}))(Tooltip);

const CustomTooltipSimple = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

const initialState = {
  lat: '',
  lng: '',
};

function PointForm({
  isVisible,
  onSubmit = () => {}
}) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [isVisible]);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      lat,
      lng
    } = state;

    if (lat && lng) {
      const parsedLat = Number(_.replace(lat, ',', ''));
      const parsedLng = Number(_.replace(lng, ',', ''));

      onSubmit([parsedLat, parsedLng]);
    }
  };

  return (
    <Fade in={ isVisible }>
      <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
        <CustomTooltip
          interactive
          placement="top-start"
          title={
            <div  dangerouslySetInnerHTML={ {__html: formatMessage('mapbiomas.map.coordinates_control.form.tooltip') } } />
          }
        >
          <InfoIcon className={ styles.infoIcon } />
        </CustomTooltip>
        <FormControl fullWidth className={ styles.formControl }>
          <TextField
            required
            id="lat-field"
            label={ formatMessage('mapbiomas.map.coordinates_control.form.fields.latitude') }
            variant="outlined"
            size="small"
            value={ state.lat }
            InputProps={{
              inputComponent: Cleave,
              inputProps: {
                options: {
                  numeral: true
                }
              }
            }}
            onChange={ handleTextChange.bind(this, 'lat') }
          />
        </FormControl>
        <FormControl fullWidth className={ styles.formControl }>
          <TextField
            required
            id="lng-field"
            label={ formatMessage('mapbiomas.map.coordinates_control.form.fields.longitude') }
            variant="outlined"
            size="small"
            value={ state.lng }
            InputProps={{
              inputComponent: Cleave,
              inputProps: {
                options: {
                  numeral: true
                }
              }
            }}
            onChange={ handleTextChange.bind(this, 'lng') }
          />
        </FormControl>
        <IconButton className={ styles.searchIconButton } type="submit">
          <MyLocationIcon />
        </IconButton>
      </form>
    </Fade>
  );
}

export default function CoordinatesBox({
  headerIsVisible,
  showBeforeAndAfterMosaic,
  onSubmit = () => {},
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [showPointForm, setShowPointForm] = useState(false);

  const togglePointForm = () => {
    setShowPointForm(!showPointForm);
  };

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithoutHeader]: !headerIsVisible,
      [styles.wrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
    }) }>
      <div id="coordinates-search" className={ styles.controlWrapper }>
        <CustomTooltipSimple arrow title={ formatMessage('mapbiomas.map.coordinates_control.tooltip') } placement="right">
          <button
            className={ classnames(styles.searchButton, {
              [classes.searchButtonActive]: showPointForm
            }) }
            onClick={ togglePointForm }
          >
            <SearchIcon />
          </button>
        </CustomTooltipSimple>
      </div>
      <PointForm
        isVisible={ showPointForm }
        onSubmit={ onSubmit }
      />
    </div>
  );
}
