export const primaryColor = '#16a085';
export const primaryColorLight = '#18af91';
export const primaryColorDark = '#14947b';
export const primaryColorContrastText = '#FFF';

export const secondaryColor = '#DCDCDC';
export const secondaryColorLight = '#E3E3E3';
export const secondaryColorDark = '#D4D4D4';
export const secondaryColorContrastText = '#555';

export const greys = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161'
};
