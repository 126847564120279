import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';
import numbro from 'numbro';
import { CSVLink } from 'react-csv';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import BaseForm from '../../../../../../layout/Header/components/BaseForm';

import PieChart from '../../components/PieChart';
import LineChart from '../../components/LineChart';
import StickyTable from '../../components/StickyTable';

import { GET_BASE_DATA } from './query';

import styles from './DashboardDialogCoverageNaturalOrAnthropicContent.module.scss';

const formatNumber = (value) => numbro(value).format({
  thousandSeparated: true,
  mantissa: 2
});

const useStyles = makeStyles((theme) => ({
  yearValue: {
    color: theme.palette.primary.main
  },
  printButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
}));

export default function DashboardDialogCoverageNaturalOrAnthropicContent({
  activeYear,
  baseParams,
  classTreeId
}) {
  const classes = useStyles();
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');
  const activeClassesLevelsListItems = _.get(baseParams, 'activeClassesLevelsListItems');
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryIds: territoryIds,
      classTreeId: classTreeId
    },
    skip: _.isEmpty(territoryIds) || !classTreeId
  });

  const classTreesList = _.get(baseData, 'classTreeList');
  const classesLevelsList = _.get(_.find(classTreesList, { key: 'natural_or_anthropic' }), 'mvClassTreeLevelsUi');

  const classesLevelsListByLevel1 = _.filter(classesLevelsList, { level: 1 });
  const filteredClassesLevelsListByLevel1Ids = _.map(classesLevelsListByLevel1, 'id');

  const coverageClassificationDataList = _.get(baseData, 'coverageClassificationDataList');
  const aggregatedCoverageClassificationDataList = _.values(_.reduce(coverageClassificationDataList, (result, obj) => {
    const key = `${ obj.year }-${ obj.classTreeNodeId }`;
    const nextValue = result[key] ? result[key].areaInHa + obj.areaInHa : obj.areaInHa;

    result[key] = {
        ...obj,
        areaInHa: nextValue
    };

    return result;
  }, {}));
  const filteredCoverageClassificationDataList = _.filter(aggregatedCoverageClassificationDataList, (item) => !_.includes(filteredClassesLevelsListByLevel1Ids, item.id));

  const parsedData = _(filteredCoverageClassificationDataList)
    .map((item) => {
      const classData = _.find(classesLevelsList, { id: item.classTreeNodeId });
  
      return {
        year: Number(_.replace(item.year, '_', '')),
        areaInHa: item.areaInHa,
        classData,
      };
    })
    .filter((item) => item.classData && _.includes(activeClassesLevelsListItems, _.get(item, 'classData.id')))
    .value();
  const years = _.sortBy(_.uniq(_.map(parsedData, 'year')));

  const parsedClassesLevelsByLevel1Data = _.map(classesLevelsListByLevel1, (classData) => {
    return {
      ...classData,
      name: _.get(classData, 'defaultName'),
      data: _.map(years, (year) => {
        const filteredCoverageClassificationData = _.filter(parsedData, (item) => {
          return item.year === year &&
            item.classData.parentId === classData.id;
        });

        return _.sumBy(filteredCoverageClassificationData, 'areaInHa');
      })
    }
  });
  const parsedClassesLevelsByLevel1PieData = _.map(classesLevelsListByLevel1, (classData) => {
    const filteredData = _.filter(parsedData, (item) => {
      return item.year === activeYear &&
        item.classData.parentId === classData.id;
    });

    return {
      ...classData,
      name: _.get(classData, 'defaultName'),
      value: _.sumBy(filteredData, 'areaInHa'),
      itemStyle: {
        color: _.get(classData, 'color')
      },
    }
  });

  const renderLoading = () => {
    return (
      <div className={ styles.loadingWrapper }>
        <CircularProgress />
      </div>
    );
  };

  const renderDownloadTableLink = () => {
    if (_.isEmpty(years) || _.isEmpty(parsedClassesLevelsByLevel1Data)) {
      return null;
    }

    let headers = [
      { key: 'class', label: 'Classe' },
    ];

    _.each(years, (year) => {
      headers.push({ key: _.toString(year), label: _.toString(year) });
    });

    const parsedData = _(parsedClassesLevelsByLevel1Data)
      .map((serie) => {
        let rowData = { class: _.get(serie, 'name') };

        _.each(_.get(serie, 'data'), (value, index) => {
          rowData[_.toString([years[index]])] = value;
        });

        return rowData;
      })
      .flatten()
      .value();

    return (
      <CSVLink
        data={ parsedData }
        headers={ headers }
        className={ classnames(styles.csvDownloadLink, classes.printButton) }
        filename="MapBiomas - Tabela de Dados.csv"
      >
        <CloudDownloadIcon />
        <span>Download</span>
      </CSVLink>
    );
  };

  const renderContent = () => {
    return (
      <div className={ styles.content }>
        {/* <BaseForm inline /> */}
        <div className={ styles.chartsWrapper }>
          <div>
            <h3>Visualização por classe (Nível 1 - { activeYear })</h3>
            <PieChart
              serie={ {
                title: 'Área (ha)',
                data: parsedClassesLevelsByLevel1PieData
              } }
            />
          </div>
          <div>
            <h3>Série histórica</h3>
            <LineChart
              categories={ years }
              series={ parsedClassesLevelsByLevel1Data }
            />
          </div>
        </div>
        <div className={ styles.dataTableWrapper }>
          <div className={ styles.tableHeader }>
            <h3 className={ styles.tableTitle }>
              <b>Tabela de dados</b>
              <span>(valores em hectares)</span>
            </h3>
            { renderDownloadTableLink() }
          </div>
          <StickyTable
            columns={ years }
            rows={ parsedClassesLevelsByLevel1Data }
          />
        </div>
      </div>
    );
  };

  if (loadingBaseData) {
    return renderLoading();
  } else if (!loadingBaseData && territories) {
    return renderContent();
  } else {
    return null;
  }
}
