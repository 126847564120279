import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

export default function PointHistoryChart({
  data,
}) {
  if (!data) {
    return null;
  }

  const categories = _.get(data, 'categories');
  const series = _.get(data, 'series');
  const yAxisNames = _.get(data, 'yAxisNames');

  return (
    <ReactEcharts
      style={{ height: 120 }}
      option={{
        grid: {
          top: 10,
          left: 10,
          bottom: 10,
          right: 10,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: categories,
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: yAxisNames,
          axisLine: {
            show: false
          }
        },
        series,
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return `${ _.get(params, 'data.data.year') } - ${ _.get(params, 'data.data.label') }`;
          }
        }
      }}
    />
  );
}