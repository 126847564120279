import React, { useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import {
  GET_TERRITORIES_AUTOCOMPLETE,
} from './query';

export default function TerritoryAutocomplete({
  clientType,
  activeTerritories,
  categoryId,
  label,
  placeholder,
  territoryLabelFilter,
  onChange,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const clientBase = useApolloClient();
  const client = getCustomClient(clientType) || clientBase;
  const [currentValue, setCurrentValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setDataFromExternalTerritories = () => {
    const territory = _.first(activeTerritories);

    const option = {
      id: _.get(territory, 'id'),
      label: _.get(territory, 'label'),
      boundingBox: _.get(territory, 'territoryBBox'),
    };

    setCurrentValue(option);
    setOptions([option]);
  };

  useEffect(() => {
    if (_.size(activeTerritories) === 1 && _.isEmpty(options)) {
      setDataFromExternalTerritories();
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(activeTerritories)) {
      setOptions([]);
      setCurrentValue(null);
    } else if (!_.isEmpty(activeTerritories) && !currentValue) {
      setDataFromExternalTerritories();
    }
  }, [activeTerritories]);

  const searchOptions = async (query) => {
    setLoading(true);
    const { data } = await client.query({
      query: GET_TERRITORIES_AUTOCOMPLETE,
      variables: {
        categoryId,
        text: query
      }
    });
    setLoading(false);

    let newOptions = [];

    if (currentValue) {
      newOptions = [currentValue];
    }

    if (data) {
      const parsedOptions = _.map(_.get(data, 'territoriesAutocomplete'), (item) => {
        const id = _.get(item, 'id');
        const boundingBox = _.get(item, 'territoryGeometry.boundingBox');
        const stringList = _.get(item, 'i18nStrings');
        const selectedString = _.find(stringList, { language: locale });
        const label = _.get(selectedString, 'stringValue');

        return {
          id,
          label,
          boundingBox: [
            [_.get(boundingBox, 'yMin'), _.get(boundingBox, 'xMin')],
            [_.get(boundingBox, 'yMax'), _.get(boundingBox, 'xMax')],
          ]
        };
      });

      newOptions = [...newOptions, ...parsedOptions];
    }

    if (territoryLabelFilter) {
      newOptions = _.filter(newOptions, (option) => {
        return _.includes(_.kebabCase(option.label), _.kebabCase(territoryLabelFilter));
      });
    }

    setOptions(newOptions);
  };

  const debouncedSearchOptions = useMemo(
    () => _.debounce(searchOptions, 300),
    [currentValue, options, categoryId]
  );

  const handleChange = (_event, newValue) => {
    if (newValue) {
      setOptions(newValue ? [newValue, ...options] : options);
      setCurrentValue(newValue);
      onChange(newValue);
    } else {
      setOptions([]);
      setCurrentValue(null);
    }
  };

  const handleInputChange = (_event, newInputValue) => {
    if (newInputValue === '') {
      setOptions(currentValue ? [currentValue] : []);
    } else {
      debouncedSearchOptions(newInputValue);
    }
  };

  return (
    <Autocomplete
      filterOptions={ (x) => x }
      getOptionLabel={ (option) => option.label }
      options={ options }
      autoComplete
      loading={ loading }
      loadingText="Carregando..."
      includeInputInList
      filterSelectedOptions
      value={ currentValue }
      noOptionsText="Pesquise..."
      onChange={ handleChange }
      onInputChange={ handleInputChange }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          size="small"
          variant="outlined"
          placeholder={ placeholder }
        />
      ) }
    />
  );
}
