import React, { useState } from 'react';
import classnames from 'classnames';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import ShareBox from '../../../../components/ShareBox';

import styles from './ShareControl.module.scss';

const useStyles = makeStyles((theme) => ({
  searchButtonActive: {
    backgroundColor: '#F1F1F1 !important',
    '& svg': {
      fill: `${ theme.palette.primary.main } !important`,
    }
  }
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

export default function ShareControl({
  headerIsVisible,
  showBeforeAndAfterMosaic,
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const handleShareButtonClick = (event) => {
    setShareAnchorEl(shareAnchorEl ? null : event.currentTarget);
  };

  const handleCloseShareBox = () => {
    setShareAnchorEl(null);
  };

  return (
    <div id="share-button" className={ classnames(styles.wrapper, {
      [styles.wrapperHeader]: headerIsVisible,
      [styles.wrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
    }) }>
      <div id="share-control" className={ styles.controlWrapper }>
        <CustomTooltip arrow title={ formatMessage('mapbiomas.map.share_box.tooltip') } placement="right">
          <button
            className={ classnames(styles.searchButton, {
              [classes.searchButtonActive]: !!shareAnchorEl
            }) }
            onClick={ handleShareButtonClick }
          >
            <ShareIcon />
          </button>
        </CustomTooltip>
      </div>
      <ShareBox
        anchorEl={ shareAnchorEl }
        onClose={ handleCloseShareBox }
        placement="top-start"
      />
    </div>
  );
}
