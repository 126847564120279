export default {
  'default': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'dhn250_level_1',
      'dhn250_level_2',
      'dhn250_level_3',
      'ugrhs',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
      'undesignated_public_forests',
      'priority_areas_for_biodiversity_conservation',
      'coastal_marine_zone',
      'basin_level_1_dnaee',
      'basin_level_2_dnaee',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'forest_concessions',
      'protected_area',
      // 'municipality_protected_area_integral_protection',
      // 'municipality_protected_area_sustainable_use',
      // 'state_protected_area_integral_protection',
      // 'state_protected_area_sustainable_use',
      // 'federal_protected_area_integral_protection',
      // 'federal_protected_area_sustainable_use',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
    ]
  },
  'degradation': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ],
    'land': [
      'indigenous_territories',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
    ]
  },
  'urban': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'basin_level_1_dnaee',
      'basin_level_2_dnaee',
      'population_arrangement',
      'metropolitan_regions',
      'urban_perimeter',
      'urban_concentration',
    ]
  },
};
