import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import toast from '../../utils/toast';
import { request } from '../../utils/api';

import validatePassword from '../../helpers/validatePassword';

import useFormatMessage from '../../hooks/useFormatMessage';

import logo from './logo.svg';

import styles from './MapBiomasCreatePassword.module.scss';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FormFooter from '../MapBiomasLogin/components/FormFooter';
import PageFooter from '../MapBiomasLogin/components/PageFooter';

export default function MapBiomasCreatePassword() {
  let recoveryToken = null;

  const callbackUrl = new URL(window.location.href).searchParams.get('callback_url');
  const urlToken = new URL(window.location.href).searchParams.get('token')
  !!urlToken
    ? recoveryToken = urlToken
    : window.location.href = '/';

  const formatMessage = useFormatMessage();
  const [state, setState] = useState({
    password: '',
    passwordConfirmation: '',
    paramsToJSON: {},
  });
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  function updateHeight() {
    setWindowHeight(window.innerHeight);
  }
  const debouncedUpdateHeight = _.debounce(updateHeight, 500);

  useEffect(() => {
    if(!!location.search.substring(1).includes('request_params')){
      let paramsToJSONCopy = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      delete paramsToJSONCopy["token"]
      setState({
        ...state,
        paramsToJSON:{
          ...paramsToJSONCopy
        }
      })
    }
    window.addEventListener('resize', debouncedUpdateHeight);

    return () => {
      window.removeEventListener('resize', debouncedUpdateHeight)
    };
  }, []);

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      password,
      passwordConfirmation
    } = state;
    const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;

    if (!validatePassword(password, passwordConfirmation)) { return; }

    const payload = {
      token: urlToken,
      password,
      request_params: { ...state.paramsToJSON }
    };
    fetch(`${API_URL}/v1/users/password_recovery`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.user) {
          window.location.href = `/login?${new URLSearchParams(state.paramsToJSON).toString()}`;
        }else {
          toast(data.errors[0], 'error');
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleConfirmPassword = () => {
    if(
        (state.password == '' || state.passwordConfirmation == '') ||
        (state.password !== state.passwordConfirmation)
      ){
        return true
    }

    return false
  }

  const isCreatePasswordButtonDisabled = handleConfirmPassword()

  return (
    <div className={ styles.wrapper } style={ { height: windowHeight } }>
      <div className={ styles.content }>
        <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        <Paper className={ styles.box }>
          <div className={ styles.contentWrapper }>
            <h1 className={ styles.title }><FormattedMessage id="mapbiomas.user.password_create.title" /></h1>
            <Typography className={ styles.subtitle }><FormattedMessage id="mapbiomas.user.password_create.subtitle" /></Typography>
            <form onSubmit={ handleSubmit }>
              <FormControl className={ styles.formControl }>
                <TextField
                  id="password-field"
                  label={ formatMessage('mapbiomas.user.password_create.fields.password') }
                  variant="outlined"
                  type="password"
                  className={ styles.filterFormInput }
                  value={ state.password }
                  onChange={ handleTextChange.bind(this, 'password') }
                  required
                />
              </FormControl>
              <FormControl className={ styles.formControl }>
                <TextField
                  id="password-confirmation-field"
                  label={ formatMessage('mapbiomas.user.password_create.fields.password_confirmation') }
                  variant="outlined"
                  type="password"
                  className={ styles.filterFormInput }
                  value={ state.passwordConfirmation }
                  onChange={ handleTextChange.bind(this, 'passwordConfirmation') }
                  required
                />
              </FormControl>
              <div className={ styles.actionsWrapper }>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={styles.button}
                  disabled={isCreatePasswordButtonDisabled}
                >
                  <FormattedMessage id="mapbiomas.user.password_create.buttons.confirm" />
                </Button>
              </div>
            </form>
            <Typography className={styles.createAcountLegend}><FormattedMessage id="mapbiomas.user.password_create.helper_link.access_acount.label" /></Typography>
            <Link className={ styles.helperLink } to={`/login?${new URLSearchParams(state.paramsToJSON).toString()}`}><FormattedMessage id="mapbiomas.user.password_create.helper_link.access_acount.title" /></Link>
          </div>
          <FormFooter/>
        </Paper>
      </div>
      <PageFooter/>
    </div>
  );
}
