import React from 'react';
import {
  TileLayer,
} from 'react-leaflet';

import useCustomMapLayer from '../../../../../../../../hooks/useCustomMapLayer';

export default function DegradationLayer({
  activeYear,
  baseParams,
}) {
  const degradationLayerData = useCustomMapLayer({ ...baseParams, activeYear });  
  const { loading, url } = degradationLayerData;

  if (loading || !url) {
    return null;
  }

  return (
    <TileLayer
      attribution='&amp;copy <a href="https://mapbiomas.org/">MapBiomas</a>'
      url={ url }
      zIndex={ 200 }
    />
  );
}