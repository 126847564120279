import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import * as turf from '@turf/turf';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';

import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import MapForm from './components/MapForm';
import MapList from './components/MapList';
import MapEditDialog from './components/MapEditDialog';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';

import { request } from '../../../../../../utils/api';
import toast from '../../../../../../utils/toast';

import resetToken from '../../../../../../helpers/resetToken';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';
import useWindowSize from '../../../../../../hooks/useWindowSize';

import { GET_BASE_DATA } from './query';

import styles from './AccountMenu.module.scss';

const LOGO_HEIGHT = 70;
const TITLE_HEIGHT = 50;
const FOOTER_HEIGHT = 30;

export default function AccountMenu({
  iframeMode,
  myMapsMode,
  newMapMode,
  onHideMenu = () => {}
}) {
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - (!iframeMode ? LOGO_HEIGHT : 0) - TITLE_HEIGHT - FOOTER_HEIGHT;

  const token = localStorage.getItem('token');
  const client = useApolloClient();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const formatMessage = useFormatMessage();
  const [showForm, setShowForm] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [currentMapData, setCurrentMapData] = useState(null);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [maps, setMaps] = useState([]);
  const { data: baseData } = useQuery(GET_BASE_DATA);

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  useEffect(() => {
    if (token) {
      request('/v1/my_maps')
        .then((data) => {
          setMaps(data);
        })
        .catch(() => {
          toast(formatMessage('mapbiomas.toast.error'), 'error');
        });
    }
  }, []);

  useEffect(() => {
    if (newMapMode) {
      setShowForm(true);
    }
  }, [newMapMode]);

  const toggleMapForm = () => {
    setShowForm(!showForm);
  };

  const toggleSignUpForm = () => {
    setShowSignUp(!showSignUp);
  };

  const togglePasswordRecoveryForm = () => {
    setShowPasswordRecovery(!showPasswordRecovery);
  };

  const handleExit = () => {
    const id = localStorage.getItem('tokenId');

    request(`/v1/tokens/${ id }`, {
      method: 'DELETE'
    })
      .then((data) => {
        if (_.get(data, 'success')) {
          resetToken();
          window.location.href = '/';
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleMapSubmit = (params) => {
    const appData = _.get(baseData, 'app');

    let payload = {
      name: params.title,
      description: params.description,
      snapshot: JSON.stringify(appData)
    };

    request('/v1/my_maps', {
      method: 'POST',
      body: JSON.stringify(payload)
    })
      .then((data) => {
        if (_.get(data, 'id')) {
          let mapsClone = _.cloneDeep(maps);
          mapsClone = _.concat(mapsClone, data);
          setMaps(mapsClone);
          setShowForm(false);
          toast(formatMessage('mapbiomas.toast.map_added'));
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleMapUpdate = (params) => {
    const appData = _.get(baseData, 'app');
    const mapId = _.get(currentMapData, 'id');
    let payload = {
      name: params.title,
      description: params.description,
    };

    if (params.updateSnapshot) {
      payload.snapshot = JSON.stringify(appData);
    }

    request(`/v1/my_maps/${ mapId }`, {
      method: 'PATCH',
      body: JSON.stringify(payload)
    })
      .then((data) => {
        if (_.get(data, 'id')) {
          let mapsClone = _.cloneDeep(maps);
          mapsClone = _.map(mapsClone, (item) => {
            if (item.id === mapId) {
              return data;
            }

            return item;
          });
          setMaps(mapsClone);
          setCurrentMapData(null);
          toast(formatMessage('mapbiomas.toast.map_updated'));
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleViewMap = (id) => {
    request(`/v1/my_maps/${ id }`)
      .then((data) => {
        if (_.get(data, 'id')) {
          let snapshot = JSON.parse(_.get(data, 'snapshot'));
          let flyTo = _.get(snapshot, 'mapFlyTo');
          let territoryPoints = [];

          // TODO: Format current user maps to add new 'territoryBBox' prop on territories
          const parsedTerritories = _.map(_.get(snapshot, 'baseParams.territories'), (territory) => {
            const territoryBBox = _.get(territory, 'territoryBBox');

            if (territoryBBox) {
              territoryPoints = _.concat(territoryPoints, territoryBBox);
            }

            return {
              ...territory,
              territoryBBox: territoryBBox || null
            };
          });

          if (!_.isEmpty(territoryPoints)) {
            const line = turf.lineString(territoryPoints);
            const bbox = turf.bbox(line);

            flyTo = [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]],
            ];
          }

          _.set(snapshot, 'baseParams.territories', parsedTerritories);
          _.set(snapshot, 'mapFlyTo', flyTo);

          if (isMobile) {
            _.set(snapshot, 'headerIsVisible', false);
          }

          updateClientAppState(snapshot);
          onHideMenu();
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleDeleteMap = (id) => {
    request(`/v1/my_maps/${ id }`, {
      method: 'DELETE'
    })
      .then(() => {
        let mapsClone = _.cloneDeep(maps);
        mapsClone = _.filter(mapsClone, (item) => item.id !== id);
        setMaps(mapsClone);
        toast(formatMessage('mapbiomas.user.my_maps.list.toast.delete_success'));
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handleEditMap = (id) => {
    const mapData = _.cloneDeep(_.find(maps, { id }));
    setCurrentMapData(mapData);
  };

  const handleMapEditClose = () => {
    setCurrentMapData(null);
  };

  const renderLogin = () => {
    return (
      <div>
        <p className={ styles.infoText }>
          <FormattedMessage id="mapbiomas.user.login.description.text_intro" />
          <a onClick={ toggleSignUpForm }><FormattedMessage id="mapbiomas.user.login.description.sign_up_label" /></a>
          <FormattedMessage id="mapbiomas.user.login.description.text_final" />
        </p>
        <LoginForm onRecoveryPassword={ togglePasswordRecoveryForm } />
      </div>
    );
  };

  return (
    <div>
      <div className={ styles.titleWrapper }>
        <h2 className={ styles.title }>
          { (!myMapsMode && !newMapMode) ?
            <FormattedMessage id="mapbiomas.sidebar.account.title" /> :
            <FormattedMessage id="mapbiomas.sidebar.my_maps.title" />
          }
        </h2>
        <Tooltip title="Esconder" onClick={ onHideMenu }>
          <IconButton className={ styles.hideMenuButton }>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={ styles.navListWrapper } style={{ height: contentHeight }}>
        { token &&
          <div className={ styles.userInfo }>
            <span>
              <PersonIcon />
              <span>{ localStorage.getItem('name') }</span>
            </span>
            <Tooltip title="Sair" onClick={ handleExit }>
              <IconButton>
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
        <div className={ styles.myMapsContentWrapper }>
          { !showSignUp && !showPasswordRecovery && !token && renderLogin() }
          { showPasswordRecovery &&
            <PasswordRecoveryForm onCancel={ togglePasswordRecoveryForm } />
          }
          { showSignUp &&
            <SignUpForm onCancel={ toggleSignUpForm } />
          }
          { token &&
            <div>
              { !showForm &&
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={ <AddIcon /> }
                  className={ styles.addButton }
                  onClick={ toggleMapForm }
                >
                  <FormattedMessage id="mapbiomas.user.my_maps.buttons.new_map" />
                </Button>
              }
              { showForm &&
                <MapForm
                  onCancel={ toggleMapForm }
                  onSubmit={ handleMapSubmit }
                />
              }
              <MapList
                data={ maps }
                onViewMap={ handleViewMap }
                onEditMap={ handleEditMap }
                onDeleteMap={ handleDeleteMap }
              />
            </div>
          }
        </div>
      </div>
      <MapEditDialog
        isOpen={ !!currentMapData }
        data={ currentMapData }
        onClose={ handleMapEditClose }
        onSubmit={ handleMapUpdate }
      />
    </div>
  );
}
