import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './InfoTooltip.module.scss';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 14,
    color: 'white',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
}))(Tooltip);

export default function InfoTooltip({ text, placement = 'bottom' }) {
  return (
    <CustomTooltip
      title={ <div dangerouslySetInnerHTML={{ __html: text }} /> }
      placement={ placement }
    >
      <InfoIcon className={ styles.icon } />
    </CustomTooltip>
  );
}
