import { gql } from 'graphql.macro';

export const GET_CATEGORIES_OF_OBJECT_TREE_NODES = gql`
  query GetCategoriesOfObjectTreeNodes($objectTreeNodeIds: [Int!]!) {
    geospatialObjectCategoryTreeNodesList(ids: $objectTreeNodeIds) {
      id
      categoryId
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData(
    $territoryIds: [Int!]!,
    $classTreeKey: String!,
    $activeClassTreeNodeIds: [Int!]!,
    $hasObjectFilter: Boolean!,
    $activeObjectCategoryIds: [Int!],
    $bufferId: Int
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
      i18nStrings {
				language
				stringValue
      }

      mvClassTreeLevelsUi (ids: $activeClassTreeNodeIds) {
        id
        classId
        color
        positionInTree
        level
        childrenIds
        parentId
        defaultName
        i18nStrings {
					language
					stringValue
        }

        classTreeNode {
          coverageClassificationDataByTree(territoryId: $territoryIds) @skip(if: $hasObjectFilter) {
            territoryId
            year
            areaInHa
          }

          coverageClassificationDataByTreeListWithObjectIntersection: coverageClassificationDataByTree(
            territoryId: $territoryIds,
						objectCategoryId: $activeObjectCategoryIds,
						bufferId: $bufferId
          ) @include(if: $hasObjectFilter) {
            territoryId
            year
            areaInHa
          }
        }
      }
    }
  }
`
