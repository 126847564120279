import React from 'react';
import _ from 'lodash';
import {
  TileLayer,
} from 'react-leaflet';

import useGenericMapLayer from '../../../../../../../../hooks/useGenericMapLayer';

export default function GenericLayer({
  activeYear,
  activeModuleContent,
  baseParams,
  opacity = 1,
  ruralPropertyCode,
  zoom,
  geometry,
}) {
  const submoduleContentKey = _.last(_.split(activeModuleContent, ':'));
  const layerData = useGenericMapLayer({
    ...baseParams,
    activeYear,
    submoduleContentKey,
    ruralPropertyCode,
    zoom,
    geometry,
  });
  const { loading, url } = layerData;

  if (loading || !url) {
    return null;
  }

  return (
    <TileLayer
      attribution='&amp;copy <a href="https://mapbiomas.org/">MapBiomas</a>'
      url={ url }
      opacity={ opacity }
      zIndex={ 200 }
    />
  );
}