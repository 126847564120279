import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './MapList.module.scss';

export default function MapList({
  data = [],
  onViewMap = () => {},
  onEditMap = () => {},
  onDeleteMap = () => {},
}) {
  const formatMessage = useFormatMessage();

  const handleDelete = (id) => {
    if (confirm(formatMessage('mapbiomas.user.my_maps.list.actions.delete_confirmation'))) {
      onDeleteMap(id);
    }
  };

  if (_.isEmpty(data)) {
    return null;
  }

  return (
    <ul className={ styles.mapList }>
      { _.map(data, (item) => {
        return (
          <li key={ `map-item-${ item.id }` } className={ styles.mapItem }>
            <div className={ styles.mapItemTextContent }>
              <h4 className={ styles.mapItemTitle }>{ item.name }</h4>
              { item.description && <p className={ styles.mapItemDescription }>{ item.description }</p> }
            </div>
            <div className={ styles.mapItemActionsContent }>
              <Tooltip title={ formatMessage('mapbiomas.user.my_maps.list.actions.show') }>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  className={ styles.mapItemViewButton }
                  onClick={ onViewMap.bind(this, item.id) }
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={ formatMessage('mapbiomas.user.my_maps.list.actions.edit') }>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  className={ classnames(styles.mapItemViewButton, styles.mapItemViewButtonSecondary) }
                  onClick={ onEditMap.bind(this, item.id) }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={ formatMessage('mapbiomas.user.my_maps.list.actions.delete') }>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  className={ classnames(styles.mapItemViewButton, styles.mapItemViewButtonSecondary) }
                  onClick={ handleDelete.bind(this, item.id) }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </li>
        );
      }) }
    </ul>
  );
}
