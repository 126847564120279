import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  query {
    app @client {
      baseParams {
        activeClassTreeNodeIds
      }
    }
  }
`;
