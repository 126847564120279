import React, { useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { FormattedMessage } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import InfrastructureMainStatisticsContent from './content/InfrastructureMainStatisticsContent';

import styles from './InfrastructureStatisticsBox.module.scss';

export default function InfrastructureStatisticsBox({
  activeYear,
  baseParams,
  statisticsDataIsVisible,
  onShowData = () => {},
  headerIsVisible,
}) {
  const activeObjectTreeNodeIds = _.get(baseParams, 'activeObjectTreeNodeIds');
  const bufferId = _.get(baseParams, 'buffer');

  if (_.isEmpty(activeObjectTreeNodeIds) || bufferId === 0) {
    return null;
  }

  const isMobile = useMediaQuery('(max-width: 768px)');

  const defaultContentParams = {
    activeYear,
    baseParams,
    statisticsDataIsVisible,
    onShowData
  };

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithHeader]: headerIsVisible,
      [styles.wrapperIsHidden]: isMobile,
    }) }>
      <header className={ styles.header }>
        <h2 className={ styles.title }>
          <span><FormattedMessage id="mapbiomas.statistics.title" /></span>
        </h2>
        <IconButton
          className={ classnames(styles.closeButton, {
            [styles.closeButtonCollapse]: isVisible
          }) }
          onClick={ toggleVisibility }
        >
          <ExpandLessIcon />
        </IconButton>
      </header>
      <Collapse in={ isVisible } timeout={ 500 } className={ styles.collapseWrapper }>
        <div className={ styles.content }>
          <InfrastructureMainStatisticsContent { ...defaultContentParams } />
        </div>
      </Collapse>
    </div>
  );
}
