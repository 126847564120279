import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import qs from 'qs';

import { FormattedMessage } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import BarChart from '../../components/BarChart';
import LineChart from '../../components/LineChart';

import styles from '../../StatisticsBox.module.scss';

import { GET_CLASS_TREE_DATA } from '../../../../../../../../graphql/queries/general';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import mapClassTreeToAPIParams from '../../../../../../../../data/mapClassTreeToAPIParams';

const API_URL = `${process.env.API_DEGRADATION_URL}/degradation/brazil/statistics/`;

const CLASS_TREE_KEYS_STACKED = [
  'degradation_fire_frequency',
  'degradation_fire_time',
  'degradation_secondary_vegetation_age',
];

export default function DegradationFragmentationEdgeArea({
  baseParams,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const activeBaseClassTreeNodeIds = _.get(baseParams, 'activeBaseClassTreeNodeIds');
  const hasBaseValueSelected = !_.isEmpty(activeBaseClassTreeNodeIds);
  const classTreeKey = _.get(baseParams, 'activeClassTreeOptionValue');
  const isStacked = _.includes(CLASS_TREE_KEYS_STACKED, classTreeKey);
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const degradationFragmentationIsolationValue = _.get(baseParams, 'degradationFragmentationIsolationValue');
  const territoryIds = _.map(_.get(baseParams, 'territories'), 'id');
  const urlParams = mapClassTreeToAPIParams[classTreeKey];
  const statisticsParamKey = (hasBaseValueSelected || degradationFragmentationIsolationValue) ? 'classId' : _.get(urlParams, 'paramKey');
  const ChartComponent = !isStacked ? LineChart : BarChart;

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: defaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    },
    client: getCustomClient('8.0')
  });
  const { data: classTreeData, loading: loadingClassTreeData,  } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: classTreeKey
    },
    client: getCustomClient('8.0')
  });
  const defaultClassTreeNodes = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  // useEffect(() => {
  //   const sampleData = _.get(baseData, 'frequencyAccumulatedGrouped');

  //   if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
  //     setEmptyData(true);
  //   } else {
  //     setEmptyData(false);
  //   }

  //   if (!loadingClassTreeData && !loading && baseData) {
  //     setChartsData({
  //       'accumulatedAreaFromAllPeriod': getAccumulatedAreaFromAllPeriodData(),
  //     });
  //   }
  // }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const getChartData = (data, valueKey) => {
    // TODO: Temp
    const years = _.range(1986, 2022);
    // const years = _.sortBy(_.uniq(_.map(data, 'year')));
    const nodeValues = _.sortBy(_.uniq(_.map(data, statisticsParamKey)));
    const nodes = (hasBaseValueSelected || degradationFragmentationIsolationValue) ? defaultClassTreeNodes : classTreeNodes;

    const parsedSeries = _.map(nodeValues, (nodeValue) => {
      const nodeData = _.find(nodes, { pixelValue: nodeValue });
      const values = _.map(years, (year) => {
        return _.get(_.find(data, { year, [statisticsParamKey]: nodeValue }), valueKey);
      });

      let label = nodeValue;

      if (hasBaseValueSelected || degradationFragmentationIsolationValue) {
        label = _.chain(nodeData)
          .get('i18nStrings')
          .find({ language: locale })
          .get('stringValue')
          .value();
      }
      
      return {
        nodeId: _.get(nodeData, 'id'),
        stack: isStacked && !(valueKey === 'percentage' && hasBaseValueSelected) ? 'year' : undefined,
        name: label,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data:values
      };
    });

    return {
      series: _.compact(parsedSeries),
      categories: years,
    };
  };

  useEffect(() => {
    if (!classTreeNodes) {
      return;
    }

    let statisticsUrl = `${ API_URL }${ _.get(urlParams, 'path') }`;

    if (hasBaseValueSelected) {
      const nodeValue = _.first(activeBaseClassTreeNodeIds);
      const nodeData = _.find(classTreeNodes, { id: nodeValue });
      const pixelValue = _.get(nodeData, 'pixelValue');

      statisticsUrl += `/${ pixelValue }`;
    } else if (degradationFragmentationIsolationValue) {
      const parsedIsolationParams = qs.parse(degradationFragmentationIsolationValue);
      statisticsUrl += `/${ _.get(parsedIsolationParams, 'mediumSize') }`;
      statisticsUrl += `/${ _.get(parsedIsolationParams, 'distance') }`;
      statisticsUrl += `/${ _.get(parsedIsolationParams, 'bigSize') }`;
    }

    statisticsUrl += `?territoryIds=${ _.join(territoryIds) }`;

    setLoading(true);
    fetch(statisticsUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        setChartsData({
          'areaChartData': getChartData(_.get(data, 'absoluteTimeSeries'), 'areaHa'),
          'percentageChartData': getChartData(_.get(data, 'relativeTimeSeries'), 'percentage'),
        });
        setLoading(false);
      });
  }, [classTreeKey, classTreeNodes, _.join(activeBaseClassTreeNodeIds), _.join(territoryIds), degradationFragmentationIsolationValue]);

  const filterActiveSeries = (series) => {
    return _.filter(series, ({ nodeId }) => _.includes(activeClassTreeNodeIds, nodeId));
  };

  const totalAreaByClasses = () => {
    const categories = _.get(chartsData, 'areaChartData.categories') || [];
    const series = _.get(chartsData, 'areaChartData.series') || [];
    const localeKey = `mapbiomas.statistics.degradation.${ classTreeKey }.${ !hasBaseValueSelected ? 'default' : 'with_base_value' }.area_title`;

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ localeKey } /></h2>
        <ChartComponent
          categories={ categories }
          series={ filterActiveSeries(series) }
          mantissa={ 1 }
        />
      </div>
    );
  };

  const percentageAreaByClasses = () => {
    const categories = _.get(chartsData, 'percentageChartData.categories') || [];
    const series = _.get(chartsData, 'percentageChartData.series') || [];
    const ChartComponent = (!isStacked || (isStacked && hasBaseValueSelected)) ? LineChart : BarChart;
    const localeKey = `mapbiomas.statistics.degradation.${ classTreeKey }.${ !hasBaseValueSelected ? 'default' : 'with_base_value' }.proportion_title`;

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ localeKey } /></h2>
        <ChartComponent
          categories={ categories }
          series={ filterActiveSeries(series) }
          mantissa={ 2 }
          unit="%"
        />
      </div>
    );
  };

  const handleShowData = () => {
    onShowData([
      _.get(chartsData, 'percentageChartData'),
    ]);
  };

  const renderData = () => {
    // if (emptyData) {
    //   return null;
    // }

    return (
      <Fragment>
        { totalAreaByClasses() }
        { percentageAreaByClasses() }
        {/* <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div> */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      { (loading || loadingClassTreeData) &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
