import React from 'react';

const LoginToForum = () => {
  fetch(`https://production.plataforma.mapbiomas.org/l/api/forum_login/${document.location.search}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }).then((res)=>res.json()).then((res)=>{
    window.location.assign(res['value']);
  })

  return (
    <div>Loading...</div>
  );
}

export default LoginToForum;
