import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import toast from '../../utils/toast';
import { request } from '../../utils/api';

import parseToken from '../../helpers/parseToken';
import useFormatMessage from '../../hooks/useFormatMessage';

import logo from './logo.svg';

import styles from './MapBiomasPasswordRecovery.module.scss';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FormFooter from '../MapBiomasLogin/components/FormFooter';
import PageFooter from '../MapBiomasLogin/components/PageFooter';

const initialState = {
  email: '',
};

export default function MapBiomasPasswordRecovery() {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);
  const [showInfo, setShowInfo] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const callbackUrl = new URL(window.location.href).searchParams.get('callback_url');
  const urlParams = location.search.substring(1);
  let paramsToJSON = {}

  function updateHeight() {
    setWindowHeight(window.innerHeight);
  }
  const debouncedUpdateHeight = _.debounce(updateHeight, 500);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateHeight);

    return () => {
      window.removeEventListener('resize', debouncedUpdateHeight)
    };
  }, []);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;
    if(!!urlParams){
      paramsToJSON = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/request_params\[(.+?)(\])/g, "$1").replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    }

    const {
      email,
    } = state;

    const payload = {
      email,
      request_params: { ...paramsToJSON }
    };

    fetch(`${API_URL}/v1/users/send_recovery_instructions`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          handleStateChange("email", "");
          toast(formatMessage('mapbiomas.toast.email_sent'), 'success');
        }else {
          toast(data.errors[0], 'error');
        }
      })
      .catch(() => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const renderInfo = () => {
    return (
      <p className={ styles.successText }><FormattedMessage id="mapbiomas.user.password_recovery.instructions" /></p>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={ handleSubmit }>
        <FormControl className={ styles.formControl }>
          <TextField
            id="email-field"
            label={ formatMessage('mapbiomas.user.password_recovery.fields.email') }
            variant="outlined"
            type="email"
            className={ styles.filterFormInput }
            value={ state.email }
            onChange={ handleTextChange.bind(this, 'email') }
            required
          />
        </FormControl>
        <div className={ styles.actionsWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            className={ styles.button }
          >
            <FormattedMessage id="mapbiomas.user.password_recovery.buttons.confirm" />
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className={ styles.wrapper } style={ { height: windowHeight } }>
      <div className={ styles.content }>
        <a href="https://mapbiomas.org/">
          <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        </a>
        <Paper className={ styles.box }>
          <div className={ styles.contentWrapper }>
            <h1 className={ styles.title }><FormattedMessage id="mapbiomas.user.password_recovery.title" /></h1>
            <Typography className={ styles.subtitle }><FormattedMessage id="mapbiomas.user.password_recovery.instructions" /></Typography>
            { showInfo && renderInfo() }
            { !showInfo && renderForm() }
            <Typography className={styles.accessAccountLegend}><FormattedMessage id="mapbiomas.user.password_recovery.access_account.legend" /></Typography>
            <Link className={ styles.helperLink } to={`/login?${urlParams}`}><FormattedMessage id="mapbiomas.user.login.helper_link.title" /></Link>
          </div>
          <FormFooter/>
        </Paper>
      </div>
      <PageFooter/>
    </div>
  );
}
