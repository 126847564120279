import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useIntl, FormattedMessage } from 'react-intl';

import BarChart from '../../components/BarChart';
import BaseValuesChart from '../../components/BaseValuesChart';
import BurnedAreaCustomChart from '../../components/BurnedAreaCustomChart';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function FireMonthlyStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const intl = useIntl()

  const years = [activeYear];
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'fire_monthly'
    },
    client: getCustomClient(clientType)
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      year: years,
      territoriesIds: territoryIds
    },
    skip: _.isArray(activeYear),
    client: getCustomClient(clientType)
  });

  const getCustomBurnedAreaByMonthData = () => {
    const data = _.get(baseData, 'burnedAreaEvolutionEachMonth');

    const parsedCategories = _.map(_.map(data, 'classTreeNodeId'), (nodeId) => {
      const nodeData = _.find(classTreeLevelsList, { id: Number(nodeId) });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');

      return label;
    });

    return {
      categories: parsedCategories,
      data,
    };
  };

  const getAnnualAreaByMonthData = () => {
    const data = _.get(baseData, 'burnedAreaEvolutionYears');
    const years = _.map(data, 'year');

    const classTreeNodeData = {};

    _.each(data, (item) => {
      _.each(_.get(item, 'acumulated'), (nodeItem) => {
        const nodeId = _.get(nodeItem, 'classTreeNodeId');
        const nodeValues = _.get(classTreeNodeData, nodeId);

        if (nodeValues) {
          _.set(classTreeNodeData, nodeId, [...nodeValues, _.get(nodeItem, 'total')]);
        } else {
          _.set(classTreeNodeData, nodeId, [_.get(nodeItem, 'total')]);
        }
      });
    });

    const parsedSeries = _.map(_.keys(classTreeNodeData), (nodeId) => {
      const nodeData = _.find(classTreeLevelsList, { id: Number(nodeId) });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');

      return {
        stack: 'year',
        name: label,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data: _.get(classTreeNodeData, nodeId),
      };
    });

    return {
      categories: years,
      series: parsedSeries
    };
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'burnedAreaEvolutionMonthCalcs[0].maximun');

    if ((!loading && errorBaseData) || _.isNull(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'customBurnedAreaByMonth': getCustomBurnedAreaByMonthData(),
        'annualAreaByMonth': getAnnualAreaByMonthData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const burnedAreaByPeriod = () => {
    const data = _.get(baseData, 'burnedAreaEvolutionMonthCalcs[0]');
    const total = _.get(data, 'totalburnedarea') || 0;
    const maxValue = _.get(data, 'maximun') || 0;
    const averageValue = _.get(data, 'average') || 0;
    const minValue = _.get(data, 'minimun') || 0;
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    return (
      <BaseValuesChart
        title={ intl.formatMessage({id: 'mapbiomas.statistics.burned.burned_area_for_selected_year'}) }
        total={ total }
        maxValue={ maxValue }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minPercentage={ minPercentage }
        maxValueTooltipKey="mapbiomas.statistics.burned.monthly.summary.max.tooltip"
        averageValueTooltipKey="mapbiomas.statistics.burned.monthly.summary.average.tooltip"
        minValueTooltipKey="mapbiomas.statistics.burned.monthly.summary.min.tooltip"
      />
    );
  };

  const customBurnedAreaByMonth = () => {
    const categories = _.get(chartsData, 'customBurnedAreaByMonth.categories') || [];
    const data = _.get(chartsData, 'customBurnedAreaByMonth.data');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{intl.formatMessage({id: 'mapbiomas.statistics.burned.area_burned_per_month'})}</span>
          <span className={ styles.secondaryTitleExtraInfo }>{ intl.formatMessage({id: 'mapbiomas.statistics.burned.area_burned_per_month_description'}) }</span>
        </h2>
        <BurnedAreaCustomChart
          categories={ categories }
          data={{
            total: _.map(data, 'totalburnedarea'),
            maximum: _.map(data, 'maximun'),
            average: _.map(data, 'average'),
            minimum: _.map(data, 'minimun'),
          }}
        />
      </div>
    );
  };

  const annualAreaByMonth = () => {
    const categories = _.get(chartsData, 'annualAreaByMonth.categories') || [];
    const series = _.get(chartsData, 'annualAreaByMonth.series') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{intl.formatMessage({id: 'mapbiomas.statistics.burned.month_burned_area_historic'})}</span>
          <span className={ styles.secondaryTitleExtraInfo }>{intl.formatMessage({id: 'mapbiomas.statistics.burned.month_burned_area_historic_description'})}</span>
        </h2>
        <BarChart
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const customBurnedAreaByMonthChartData = _.get(chartsData, 'customBurnedAreaByMonth');
    const customBurnedAreaByMonthChartDataCategories = _.get(customBurnedAreaByMonthChartData, 'categories') || [];
    const customBurnedAreaByMonthChartDataData = _.get(customBurnedAreaByMonthChartData, 'data');

    const customBurnedAreaByMonthChart = {
      title: intl.formatMessage({id: 'mapbiomas.statistics.burned.area_burned_per_month'}),
      columns: customBurnedAreaByMonthChartDataCategories,
      rows: [
        {
          name: intl.formatMessage({id: 'mapbiomas.statistics.burned.burned_area'}),
          data: _.map(customBurnedAreaByMonthChartDataData, 'totalburnedarea')
        },
        {
          name: intl.formatMessage({id: 'mapbiomas.statistics.burned.max_value'}),
          data: _.map(customBurnedAreaByMonthChartDataData, 'maximun')
        },
        {
          name: intl.formatMessage({id: 'mapbiomas.statistics.burned.average_value'}),
          data: _.map(customBurnedAreaByMonthChartDataData, 'average')
        },
        {
          name: intl.formatMessage({id: 'mapbiomas.statistics.burned.min_value'}),
          data: _.map(customBurnedAreaByMonthChartDataData, 'minimun')
        },
      ]
    };

    const annualAreaByMonthChartData = _.get(chartsData, 'annualAreaByMonth');
    const annualAreaByMonthChartDataCategories = _.get(annualAreaByMonthChartData, 'categories') || [];
    const annualAreaByMonthChartDataSeries = _.get(annualAreaByMonthChartData, 'series') || [];

    const annualAreaByMonthChart = {
      title: intl.formatMessage({id: 'mapbiomas.statistics.burned.month_burned_area_historic'}),
      columns: annualAreaByMonthChartDataCategories,
      rows: _.map(annualAreaByMonthChartDataSeries, (serie) => {
        return {
          name: _.get(serie, 'name'),
          data: _.get(serie, 'data')
        };
      })
    };

    onShowData([
      customBurnedAreaByMonthChart,
      annualAreaByMonthChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { burnedAreaByPeriod() }
        { customBurnedAreaByMonth() }
        { annualAreaByMonth() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
