import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeYear
      activeModule
      activeModuleContent
      baseParams {
        groupType
        degradationType
        degradationFragmentationIsolationValue
        degradationCrossingValues {
          degradation_fragmentation_edge_area
          degradation_fragmentation_fragment_size
          degradation_fragmentation_isolation_medium_size
          degradation_fragmentation_isolation_distance
          degradation_fragmentation_isolation_big_size
          degradation_fire_frequency
          degradation_fire_time
          degradation_secondary_vegetation_age
          parsedParams
        }
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        classesViewMode
        activeClassTreeNodeIds
        activeBaseClassTreeNodeIds
        activeClassTreeOptionValue
        activeObjectTreeNodeIds
        activeSubmodule
        activeClassesLevelsListItems
        activeTransitionsClassesListItems
        activePastureQualityQualityClassesListItems
        yearRange
        buffer
        crossingEnabled
        crossingTerritoryType
        crossingTerritories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
      }
      headerIsVisible
      mapFlyTo
      mapPointInfo {
        latlng
        parsedBBox
      }
      mapPosition
      mapboxMode
      showClassInfo
      showDashboardInfo
      timelineLimitsRange
      showBeforeAndAfterMosaic
      transitionClassTreeNodeId
      ruralPropertyCode
      carFeature
      geometry
      geometryMode
    }
  }
`;

export const GET_TERRITORIES_CATEGORIES_LIST = gql`
  query TerritoriesCategoriesList {
    territoryCategoriesList {
      id
      key
      parentId
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;

export const GET_TERRITORIES_LIST = gql`
  query TerritoriesList($categoryId: Int!) {
    territoriesList(categoryId: $categoryId) {
      id
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;
