import React from 'react';
import _ from 'lodash';
import {
  TileLayer,
} from 'react-leaflet';

import useGenericBaseLayer from '../../../../../../../../hooks/useGenericBaseLayer';

export default function GenericBaseLayer({
  activeYear,
  baseParams,
  pathKey,
  layerKey,
  territoryId,
  zIndex = 300,
}) {
  const layerData = useGenericBaseLayer({
    ...baseParams,
    activeYear,
    pathKey,
    layer: layerKey,
    territoryId,
  });
  const { loading, url } = layerData;

  if (loading || !url) {
    return null;
  }

  return (
    <TileLayer
      attribution='&amp;copy <a href="https://mapbiomas.org/">MapBiomas</a>'
      url={ url }
      zIndex={ zIndex }
    />
  );
}