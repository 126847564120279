import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import copy from 'copy-to-clipboard';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import toast from '../../../../utils/toast';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import styles from './ShareBox.module.scss';

export default function ShareBox({
  anchorEl,
  onClose = () => {},
  pageLink = window.location.href,
  placement = 'bottom',
}) {
  const formatMessage = useFormatMessage();
  const open = Boolean(anchorEl);
  const title = 'MapBiomas';

  const handleCopy = () => {
    if (copy) {
      copy(pageLink);
      toast(formatMessage('mapbiomas.map.share_box.actions.copy_success'));
    }
    onClose();
  };

  return (
    <Popper open={ open } anchorEl={ anchorEl } placement={ placement } transition style={ { zIndex: 9999 } }>
      {({ TransitionProps }) => (
        <Fade { ...TransitionProps } timeout={ 350 }>
          <ClickAwayListener onClickAway={ onClose }>
            <div className={ styles.boxWrapper }>
              <h2 className={ styles.title }><FormattedMessage id="mapbiomas.map.share_box.actions.title" />:</h2>
              <ul className={ styles.socialList }>
                <li className={ styles.socialItem }>
                  <FacebookShareButton
                    quote={ title }
                    url={ pageLink }
                  >
                    <FacebookIcon size={ 32 } round={ true } />
                  </FacebookShareButton>
                </li>
                <li className={ styles.socialItem }>
                  <TwitterShareButton
                    title={ title }
                    url={ pageLink }
                    >
                    <TwitterIcon size={ 32 } round={ true } />
                  </TwitterShareButton>
                </li>
                <li className={ styles.socialItem }>
                  <WhatsappShareButton
                    title={ title }
                    url={ pageLink }
                  >
                    <WhatsappIcon size={ 32 } round={ true } />
                  </WhatsappShareButton>
                </li>
              </ul>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ handleCopy }
              >
                <FormattedMessage id="mapbiomas.map.share_box.actions.copy" />
              </Button>
            </div>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  );
}
