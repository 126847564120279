export function namespacedTokenKey() {
  let splitLocation = window.location.pathname.split('/')

  return `${splitLocation[1]}/token`
}

export async function getCurrentTimeFromWorldClock() {
  let currentTime = null
  try {
    let response = await fetch('http://worldclockapi.com/api/json/utc/now')
    let json = await response.json()

    if (!json.currentDateTime) {
      throw Error('World clock API has returned a valid json but with an unexpected format')
    }

    currentTime = new Date(json.currentDateTime)
  } catch (e) {
    console.warn(e)
    currentTime = new Date()
  }

  return currentTime
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getUserIdFromToken(currentTime = new Date()) {
  try {
    let token = localStorage.getItem('token')
    let claims = parseJwt(token)

    let expirationDate = new Date(claims.exp * 1000)

    if (currentTime.getTime() > expirationDate.getTime()) {
      localStorage.removeItem('token')
      return null
    }

    return claims.sub
  } catch (e) {
    localStorage.removeItem('token')
    return null
  }
}
