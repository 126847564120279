import { useEffect } from 'react';

import { request } from '../../utils/api';

export default function ConfirmationPage() {
  let token = null;

  const urlToken = new URL(window.location.href).searchParams.get('token')
  !!urlToken
    ? token = urlToken
    : window.location.href = '/';

  useEffect(() => {
    const payload = { token: urlToken };

    request('/users/confirm_account', {
      method: 'POST',
      body: JSON.stringify(payload)
    })
      .then(() => {
        window.location.href = '/?confirmation=true';
      })
      .catch(() => {
        toast('Ocorreu um erro.', 'error');
      });
  }, [])

  return null;
}
