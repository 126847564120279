import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';

function tooltipFormatter(params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span>${ params[0].axisValue }</span>`;

  params.forEach((item) => {
    const value = _.isObject(item.data) ? item.data.value : item.data;

    if (value) {
      const valueLabel = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
      const paramValue = `<span>${ colorSpan(item.color) } ${ item.seriesName }: ${ valueLabel }</span>`;

      str += paramValue;
    }
  });

  return str;
}

export default function LineChart({
  categories = [],
  series = [],
}) {
  return (
    <ReactEcharts
      style={ { height: 380 } }
      option={ {
        tooltip: {
          confine: true,
          textStyle: {
            align: "left",
            color: "#444",
            fontSize: 13
          },
          backgroundColor: "#FFFFFF",
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          extraCssText: "box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);",
          trigger: "axis",
          formatter: tooltipFormatter,
        },
        grid: {
          top: "8%",
          right: "20px",
          bottom: "8%",
          left: "20px",
          containLabel: true
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow"
          },
          axisLabel: {
            fontSize: 11,
            color: "#767676"
          },
          axisLine: {
            color: "#DDD"
          },
          boundaryGap: false,
          data: categories
        },
        legend: {
          show: false
        },
        yAxis: {
          axisTick: {
            show: false
          },
          axisLine: {
            color: "#DDD"
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.05)"
            }
          },
          axisLabel: {
            formatter: (value) => {
              const valueLabel = numbro(value).format({
                thousandSeparated: true,
                mantissa: 0
              });
      
              return valueLabel;
            },
            fontSize: 11,
            color: "#767676"
          }
        },
        series: _.map(series, (serie) => {
          return {
            ...serie,
            type: 'line',
            smooth: true,
          };
        }),
        toolbox: {
          feature: {
            saveAsImage: {
              name: 'MapBiomas',
              title: 'Download',
            }
          }
        },
      } }
      notMerge={ true }
    />
  );
}
