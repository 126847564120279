import customClient from '../core/custom-apollo-client/custom-apollo-client';
import customClientSecondary from '../core/custom-apollo-client-secondary/custom-apollo-client-secondary';

const mapVersionToClient = {
  '7.1': customClientSecondary,
  '8.0': customClient,
};

export default function getCustomClient(version) {
  return mapVersionToClient[version] || undefined;
};
