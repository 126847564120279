import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  query GetBaseData($territoryIds: [Int!]!, $classTreeId: Int!) {
    classTree(id: $classTreeId) {
      id
      key
      i18nStrings {
				language
				stringValue
      }
      mvClassTreeLevelsUi {
        id
        classId
        color
        positionInTree
        level
        parentId
        defaultName
        i18nStrings {
          language
					stringValue
        }
        classTreeNode {
          classe {
            id
            key
            pixelValue
          }
        }
      }
    }

    coverageClassificationDataList: coverageClassificationDataByTreeList(
      classTreeId: $classTreeId,
			bufferId: null,
			territoryIds: $territoryIds
    ) {
      territoryId
      year
      classTreeNodeId
      areaInHa
    }
  }
`;
