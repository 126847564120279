import _ from 'lodash';

const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;

export function request(
  endpoint,
  originConfig,
  customHeaders = { 'Content-Type': 'application/json' }
) {
  const token = localStorage.getItem('token');
  let config = _.extend({}, originConfig);
  let url = API_URL;

  if (token) {
    config.headers = _.extend(
      { 'Authorization': `Bearer ${ token }` },
      customHeaders
    );
  } else {
    config.headers = customHeaders;
  }

  return fetch(url + endpoint, config)
    .then(response => {
      if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (_.includes(contentType, 'xls') || _.includes(contentType, 'pdf')) {
          return response.blob();
        } else if (_.includes(contentType, 'json')) {
          return response.json();
        } else if (_.includes(contentType, 'text')) {
          return response.text();
        }

        return response;
      } else {
        return Promise.reject({
          status: response.status,
          statusText: response.statusText
        });
      }
    })
    .then(json => json)
    .catch(error => {
      /* eslint-disable */
      console.log(JSON.stringify(error, undefined, 2));
      /* eslint-disable */

      return Promise.reject(error);
    });
}
