import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import * as turf from '@turf/turf';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { GET_BASE_DATA } from '../../../../../../query';
import toast from '../../../../../../../../utils/toast';

import styles from './CARBox.module.scss';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

function fetchCARCodeLayerData(carCode) {
  return fetch(`${ API_URL }/maps/property/${ carCode }`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json());
}

const initialState = {
  code: '',
};

function CARForm({
  isVisible,
  ruralPropertyCode,
  onFlyTo = () => { },
  onSearchPoint = () => { },
  onRuralPropertyCodeChange = () => { },
  updateClientAppState = () => { },
}) {

  const [state, setState] = useState(initialState);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setState(initialState);
  }, [isVisible]);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  useEffect(() => {
    if (ruralPropertyCode) {
      setIsSearching(true);
      
      fetchCARCodeLayerData(ruralPropertyCode)
        .then((data) => {
          setIsSearching(false);

          const feature = _.get(data, '[0]');

          if (feature) {
            const bbox = turf.bbox(feature);
            const parsedBBox = [
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ];

            onFlyTo(parsedBBox);

            const line = turf.lineString(parsedBBox);
            const center = turf.center(line);
            const coordinates = _.get(center, 'geometry.coordinates');
            const point = {
              lat: _.first(coordinates),
              lng: _.last(coordinates),
            };

            onSearchPoint({
              feature,
              point
            });
          } else {
            toast('Não foi possível localizar o CAR informado.', 'error');
          }
        });
    }
  }, [ruralPropertyCode]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const code = state.code;
    updateClientAppState({
      ruralPropertyCode: code
    });
    onRuralPropertyCodeChange(code);
  };

  const handleRemove = () => {
    handleStateChange('code', '');
    updateClientAppState({
      carFeature: null,
      ruralPropertyCode: null
    });
    onRuralPropertyCodeChange(null);
  };

  return (
    <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          required
          id="code-field"
          label="Código do CAR"
          variant="outlined"
          size="small"
          value={ state.code }
          onChange={ handleTextChange.bind(this, 'code') }
        />
      </FormControl>
      <div className={ styles.actionWrapper }>
        { ruralPropertyCode &&
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={ styles.removeButton }
            onClick={ handleRemove }
          >
            <CloseIcon />
            <span>Limpar</span>
          </Button>
        }
        { isSearching &&
          <span className={ styles.loadingProgressWrapper }>
            <CircularProgress size= { 20 } color="secondary" />
          </span>
        }
        <Button
          variant="contained"
          color="primary"
          size="small"
          type="submit"
          className={ styles.searchButton }
          disabled={ isSearching }
        >
          <SearchIcon />
          <span>Localizar</span>
        </Button>
      </div>
    </form>
  );
}

export default function CARBox({
  onFlyTo = () => { },
  onFeatureChange = () => { },
  updateClientAppState = () => { }
}) {
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const ruralPropertyCode = _.get(baseData, 'app.ruralPropertyCode');

  const handleSearchPoint = ({ feature, point }) => {
    if (point) {
      onFeatureChange(feature);
    }
  };

  return (
    <CARForm
      isVisible={ true }
      ruralPropertyCode={ ruralPropertyCode }
      onFlyTo={ onFlyTo }
      onSearchPoint={ handleSearchPoint }
      onRuralPropertyCodeChange={(code) => {
        updateClientAppState({
          ruralPropertyCode: code
        });
      }}
      updateClientAppState={ updateClientAppState }
    />
  );
}
