import React from 'react';
import { Source, Layer } from 'react-map-gl';

import useGenericMapLayer from '../../../../../../hooks/useGenericMapLayer';

export default function MapboxCoverageLayer({
  activeYear,
  baseParams,
  ruralPropertyCode,
  geometry,
  opacity
}) {
  const submoduleContentKey = 'coverage_3d';
  const layerData = useGenericMapLayer({
    ...baseParams,
    activeYear,
    submoduleContentKey,
    ruralPropertyCode,
    geometry,
  });
  const { loading, url } = layerData;

  if (loading || !url) {
    return null;
  }

  return (
    <Source
      id="mapbiomas-brasil-3d"
      type="raster"
      key={ `coverage-3d-${ url }` }
      tiles={ [
        url
      ] }
      tileSize={ 256 }
    >
      <Layer
        id="layer-mapbiomas-brasil-3d"
        sourceLayer="layer-mapbiomas-brasil-3d"
        type="raster"
        source="layer-mapbiomas-brasil-3d"
        paint={ {
          'raster-opacity': opacity
        } }
      />
    </Source>
  );
}