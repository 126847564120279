import React, { useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import toast from '../../../../../../../../utils/toast';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './GeometryControl.module.scss';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

export default function GeometryControl({
  mode,
  onShapefileChange = () => {},
  onDrawModeChange = () => {},
}) {
  const formatMessage = useFormatMessage();

  const [error, setError] = useState(null);

  const handleShapefileChange = async (event) => {
    if (event.target.files) {
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append('file', file);
      const result = await fetch(`${ API_URL }/maps/upload_shapefile`, {
        method: 'POST',
        body: formData,
      });

      const data = await result.json();
      const geoJson = _.get(data, 'geoJson');
      let errorKey = null;

      if (geoJson) {
        const parsedData = JSON.stringify(geoJson);
        onShapefileChange(parsedData);
        setError(null);
      } else if (_.get(data, 'error') && _.get(data, 'message') === 'Max shapefile area: 100000 ha.') {
        errorKey = 'area';
      } else if (_.get(data, 'error') && _.get(data, 'message') === 'Shapefile projection must be EPSG:4674.') {
        errorKey = 'projection';
      } else if (_.get(data, 'error') && _.get(data, 'message') === 'Shapefile must have only one polygon.') {
        errorKey = 'polygons';
      } else if (_.get(data, 'error')) {
        errorKey = 'general';
      }

      if (errorKey) {
        const errorMessage = formatMessage('mapbiomas.header.base_form.geometry_type.errors.' + errorKey);
        setError(errorMessage);
        toast(errorMessage, 'error');
      }
    }
  };

  return (
    <div className={ styles.wrapper }>
      <p className={ styles.infoText }><FormattedMessage id="mapbiomas.header.base_form.geometry_type.info" /></p>
      <div className={ styles.buttonsWrapper }>
        <Button
          variant="contained"
          size="small"
          className={ classNames(styles.actionButton, {
            [styles.actionButtonActive]: mode === 'polygon'
          }) }
          onClick={ onDrawModeChange.bind(this, 'polygon') }
        >
          <FormattedMessage id="mapbiomas.header.base_form.geometry_type.options.polygon" />
        </Button>
        <Button
          variant="contained"
          size="small"
          className={ classNames(styles.actionButton, {
            [styles.actionButtonActive]: mode === 'rectangle'
          }) }
          onClick={ onDrawModeChange.bind(this, 'rectangle') }
        >
          <FormattedMessage id="mapbiomas.header.base_form.geometry_type.options.rectangle" />
        </Button>
      </div>
      { mode &&
        <p className={ styles.infoTextSecondary }><FormattedMessage id="mapbiomas.header.base_form.geometry_type.info_active" /></p>
      }
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          key={ `${ _.uniqueId() }-${ _.kebabCase(error) }` }
          label="Shapefile"
          type="file"
          variant="outlined"
          size="small"
          onChange={ handleShapefileChange }
        />
      </FormControl>
      { error &&
        <p className={ styles.infoTextError }>{ error }</p>
      }
    </div>
  );
}
