import client from '../core/apollo-client';

export default function toast(content, severity = 'success') {
  client.writeData({
    data: {
      toast: {
        __typename: 'ToastState',
        content,
        severity,
        visible: true
      }
    }
  });
}
