import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './QualityClasses.module.scss';

export default function QualityClasses() {
  return (
    <div>
      <h2 className={ styles.title }><FormattedMessage id={ `mapbiomas.header.classes_control.title` } /></h2>
      <p className={ styles.text }><FormattedMessage id={ `mapbiomas.header.quality_classes.description` } /></p>
      <div className={ styles.legendGradient } />
    </div>
  );
}
