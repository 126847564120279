import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import LineChart from '../../components/LineChart';
import PieChart from '../../components/PieChart';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';
import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

export default function CoverageMainStatisticsContentCustom({
  activeYear,
  baseParams,
  chartType,
  clientType,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
  unit,
  valueMantissa = 0,
  ruralPropertyCode,
  geometry,
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
    ruralPropertyCode,
    geometry
  });
  const { loading, data, pixelValues } = statisticsData;

  const currentYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const formatMessage = useFormatMessage();

  const [chartsData, setChartsData] = useState(null);

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: getCustomClient(clientType)
  });

  const parseChartsData = () => {
    const years = _.orderBy(_.map(data, 'year'));
    const activeYearIndex = _.findIndex(years, (year) => year === activeYear);
    const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

    const parsedData = _.map(pixelValues, (pixelValue) => {
      const nodeData = _.find(classesLevelsList, { pixelValue });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const className = `${ _.join(_.get(nodeData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;


      return {
        ...(nodeData || {}),
        name: className,
        data: _.map(years, (year) => {
          const yearData = _.find(data, { year });
          const pixelArea = _.find(_.get(yearData, 'areas'), { pixelId: pixelValue });

          return _.get(pixelArea, 'value', 0);
        })
      };
    });

    const classViewChartData = _(parsedData)
      .filter({ level: 1 })
      .map((item) => {
        const itemValue = _.get(item, `data[${ activeYearIndex }]`);

        return {
          name: _.get(item, 'name'),
          value: itemValue,
          itemStyle: {
            color: _.get(item, 'color')
          },
        };
      })
      .value();

    const historicalSeriesChartData = _.map(parsedData, (item) => {
      return {
        name: item.name,
        color: item.color,
        data: item.data,
        level: item.level,
        padding: item.level * 12,
      };
    });

    return {
      years,
      classViewChartData,
      classViewChartDataUnit: null,
      historicalSeriesChartData,
      historicalSeriesChartDataUnit: null,
    };
  };

  useEffect(() => {
    if (!loading && data) {
      setChartsData(parseChartsData());
    }
  }, [loading]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const renderClassViewChart = () => {
    const data = _.get(chartsData, 'classViewChartData');
    const classViewChartDataUnit = _.get(chartsData, 'classViewChartDataUnit');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: currentYear }} /></h2>
        <PieChart
          serie={{
            title: classViewChartDataUnit || unit || formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data
          }}
          mantissa={ valueMantissa }
        />
      </div>
    );
  };

  const renderHistoricalSeriesChart = () => {
    const years = _.get(chartsData, 'years');
    const data = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChartDataUnit = _.get(chartsData, 'historicalSeriesChartDataUnit');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title" /></h2>
        <LineChart
          chartType={ chartType }
          categories={ years }
          series={ data }
          unit={ historicalSeriesChartDataUnit || unit }
          mantissa={ valueMantissa }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'classViewChartData');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title', { year: currentYear }),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
      mantissa: valueMantissa
    };

    const years = _.get(chartsData, 'years');
    const historicalSeriesChartData = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title'),
      columns: years,
      rows: historicalSeriesChartData,
      mantissa: valueMantissa
    };

    onShowData([
      classViewChart,
      historicalSeriesChart,
    ]);
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { renderClassViewChart() }
      { renderHistoricalSeriesChart() }
      <div id="data-view-button" className={ styles.actionWrapper }>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={ handleShowData }
          className={ styles.actionButton }
        >
          <TableChartIcon />
          <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
        </Button>
      </div>
    </Fragment>
  );
}
