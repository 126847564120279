import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from './MapForm.module.scss';

const initialState = {
  title: '',
  description: '',
  updateSnapshot: false,
};

export default function MapForm({
  editMode = false,
  data,
  onCancel = () => {},
  onSubmit = () => {},
}) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (data) {
      setState({
        title: _.get(data, 'name'),
        description: _.get(data, 'description'),
      });
    }
  }, [data])

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleUpdateSnapshotToggle = () => {
    handleStateChange('updateSnapshot', !state.updateSnapshot);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setState(initialState);
    onSubmit(state);
  };

  const baseTranslationKey = !editMode ? 'new_map' : 'edit_map';

  return (
    <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
      { !editMode && <h3 className={ styles.title }><FormattedMessage id="mapbiomas.user.my_maps.forms.new_map.title" /></h3> }
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          required
          id="title-field"
          label={ formatMessage('mapbiomas.user.my_maps.forms.new_map.fields.title') }
          type="text"
          variant="outlined"
          size="small"
          value={ state.title }
          onChange={ handleTextChange.bind(this, 'title') }
        />
      </FormControl>
      <FormControl fullWidth className={ styles.formControl }>
        <TextField
          id="description-field"
          label={ formatMessage('mapbiomas.user.my_maps.forms.new_map.fields.description') }
          variant="outlined"
          size="small"
          multiline
          rows={ 2 }
          value={ state.description }
          onChange={ handleTextChange.bind(this, 'description') }
        />
      </FormControl>
      { editMode &&
        <FormControlLabel
          classes={ {
            root: styles.checkboxRoot,
            label: styles.checkboxLabel,
          } }
          control={
            <Checkbox
              checked={ state.updateSnapshot }
              onChange={ handleUpdateSnapshotToggle }
              name="updateSnapshot"
              color="primary"
              size="small"
            />
          }
          label={ formatMessage('mapbiomas.user.my_maps.forms.edit_map.fields.update_snapshot') }
        />
      }
      <div className={ styles.actionsWrapper }>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={ onCancel }
        >
          <FormattedMessage id="mapbiomas.user.my_maps.forms.new_map.buttons.cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          type="submit"
        >
          <FormattedMessage id={ `mapbiomas.user.my_maps.forms.${ baseTranslationKey }.buttons.confirm` } />
        </Button>
      </div>
    </form>
  )
}
