import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import toast from '../../utils/toast';
import { request } from '../../utils/api';

import parseToken from '../../helpers/parseToken';
import useFormatMessage from '../../hooks/useFormatMessage';

import logo from './logo.svg';

import styles from './MapBiomasLogin.module.scss';
import { FilledInput, InputLabel, OutlinedInput, Typography } from '@material-ui/core';

import FormFooter from './components/FormFooter';
import PageFooter from './components/PageFooter';

const initialState = {
  login: '',
  password: '',
};

export default function MapBiomasLogin() {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isCreatedPassword = new URL(window.location.href).searchParams.get('created_password');

  const urlParams = location.search.substring(1) || "";
  let paramsToJSON = {}

  function updateHeight() {
    setWindowHeight(window.innerHeight);
  }
  const debouncedUpdateHeight = _.debounce(updateHeight, 500);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateHeight);
    if(isCreatedPassword){
      toast(formatMessage('mapbiomas.toast.created_password'), 'success');
    }

    return () => {
      window.removeEventListener('resize', debouncedUpdateHeight)
    };
  }, []);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { login, password } = state;

    const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;

    const callbackUrl = new URL(window.location.href).searchParams.get('request_params[callback_url]') ;
    const sso = new URL(window.location.href).searchParams.get('request_params[sso]') ;
    const sig = new URL(window.location.href).searchParams.get('request_params[sig]') ;

    if(!!urlParams){
      paramsToJSON = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/request_params\[(.+?)(\])/g, "$1").replace(/"/g, '\\"').replace(/&/g, '","').replace(/=([a-zA-Z0-9_.-])/g,'":"$1') + '"}')
    }

    const payload = {
      email: login,
      password,
      request_params: {
        ...paramsToJSON
      }
    };

    const customHeaders = { 'Content-Type': 'application/json' }

    const token = localStorage.getItem('token');
    let config = _.extend({}, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    let url = API_URL;


    if (token) {
      config.headers = _.extend(
        { 'Authorization': `Bearer ${ token }` },
        customHeaders
      );
    } else {
      config.headers = customHeaders;
    }

    fetch(`${url}/v1/tokens`, config)
      .then((response) => response.json())
      .then((data) => {
        if(data.success){
          const id = _.get(data, 'id');
          const token = _.get(data, 'token');
          if (token) {

            localStorage.setItem('tokenId', id);
            parseToken(token);
            if (callbackUrl) {
              let paramsToUrl = ''
              Object.keys(paramsToJSON).map(function(key, index) {
                paramsToUrl += `request_params[${key}]=${paramsToJSON[key]}&`
              });
              window.location.href = `${decodeURIComponent(callbackUrl)}/?${encodeURI(paramsToUrl)}token=${token}`;
            } else {
              window.location.href = '/?login=true';
            }
          }
        }else {
          toast(data.message, 'error');
        }

      })
      .catch((error) => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  return (
    <div className={ styles.wrapper } style={ { height: windowHeight } }>
      <div className={ styles.content }>
        <a href="https://mapbiomas.org/">
          <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        </a>
        <Paper className={ styles.box }>
          <div className={ styles.contentWrapper }>
            <h1 className={ styles.title }><FormattedMessage id="login_page.title" /></h1>
            <Typography className={ styles.subtitle }><FormattedMessage id="login_page.subtitle" /></Typography>
            <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
              <FormControl fullWidth className={ `${styles.formControl} ${styles.emailField}` }>
                <TextField
                  required
                  id="login-field"
                  label={ formatMessage('mapbiomas.user.login.fields.email') }
                  type="text"
                  variant="outlined"
                  size="small"
                  value={ state.login }
                  onChange={ handleTextChange.bind(this, 'login') }
                />
              </FormControl>
              <FormControl fullWidth className={ styles.formControl }>
                <TextField
                  required
                  id="password-field"
                  label={ formatMessage('mapbiomas.user.login.fields.password') }
                  type="password"
                  variant="outlined"
                  size="small"
                  value={ state.password }
                  onChange={ handleTextChange.bind(this, 'password') }
                />
              </FormControl>
              <div className={ styles.actionsWrapper }>
                <Link className={ styles.helperLink } to={`/esqueci-minha-senha?${urlParams}`}><FormattedMessage id="mapbiomas.user.login.forgot_password" /></Link>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  className={styles.button}
                >
                  <FormattedMessage id="mapbiomas.user.login.buttons.login" />
                </Button>
              </div>
            </form>
            <Typography className={styles.createAcountLegend}><FormattedMessage id="mapbiomas.user.create_account.helper_link.create_account_label" /></Typography>
            <Link className={ styles.helperLink } to={`/criar-minha-conta?${urlParams}`}><FormattedMessage id="mapbiomas.user.create_account.helper_link.create_acount_title" /></Link>
          </div>
          <FormFooter/>
        </Paper>
      </div>
      <PageFooter/>
    </div>
  );
}
