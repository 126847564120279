import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useIntl,FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import toast from '../../utils/toast';
import { request } from '../../utils/api';

import parseToken from '../../helpers/parseToken';
import useFormatMessage from '../../hooks/useFormatMessage';

import logo from './../MapBiomasLogin/logo.svg';

import styles from './MapbiomasCreateAccount.module.scss';
import { Typography } from '@material-ui/core';
import FormFooter from '../MapBiomasLogin/components/FormFooter';
import PageFooter from '../MapBiomasLogin/components/PageFooter';

const initialState = {
  name: '',
  login: '',
  password: '',
  organization: '',
  motivation: '',
  privacyCheck: true,
};

export default function MapbiomasCreateAccount() {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const urlParams = location.search.substring(1);
  let paramsToJSON = {}

  const intl = useIntl()

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_MAPBIOMAS_LOGIN_API_BASE_URL;
    const { login, organization, name, motivation } = state;

    if(!!urlParams){
      paramsToJSON = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/request_params\[(.+?)(\])/g, "$1").replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    }

    const payload = {
      user:{
        email: login,
        organization,
        name,
        motivation,
      },
      request_params: {
        ...paramsToJSON
      }
    };

    fetch(`${API_URL}/v1/users`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if(data.user){
          setState({
            name: '',
            login: '',
            password: '',
            organization: '',
            motivation: '',
            privacyCheck: true,
          })
          toast(formatMessage('mapbiomas.toast.confirm_account'), 'success');
        }else {
          toast(data.email, 'error');
        }

      })
      .catch((error) => {
        toast(formatMessage('mapbiomas.toast.error'), 'error');
      });
  };

  const handlePrivacyCheckChange = () => {
    setState({
      ...state,
      privacyCheck: !state.privacyCheck
    });
  }

  return (
    <div className={ styles.wrapper } style={ { height: windowHeight } }>
      <div className={ styles.content }>
        <a href="https://mapbiomas.org/">
          <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        </a>
        <Paper className={ styles.box }>
          <div className={ styles.contentWrapper }>
            <h1 className={ styles.title }><FormattedMessage id="mapbiomas.user.create_account.title" /></h1>
            <Typography className={ styles.subtitle }><FormattedMessage id="mapbiomas.user.create_account.instructions" /></Typography>
            <form className={ styles.formWrapper } onSubmit={ handleSubmit }>
              <FormControl fullWidth className={ `${styles.formControl} ${styles.mb}` }>
                <TextField
                  required
                  id="name-field"
                  label={ formatMessage('mapbiomas.user.create_account.fields.name') }
                  type="text"
                  variant="outlined"
                  size="small"
                  value={ state.name }
                  onChange={ handleTextChange.bind(this, 'name') }
                />
              </FormControl>
              <FormControl fullWidth className={ `${styles.formControl} ${styles.mb}` }>
                <TextField
                  required
                  id="login-field"
                  label={ formatMessage('mapbiomas.user.login.fields.email') }
                  type="text"
                  variant="outlined"
                  size="small"
                  value={ state.login }
                  onChange={ handleTextChange.bind(this, 'login') }
                />
              </FormControl>
              <FormControl fullWidth className={ `${styles.formControl} ${styles.mb}` }>
                <TextField
                  id="organization-field"
                  label={ formatMessage('mapbiomas.user.create_account.fields.organization') }
                  type="text"
                  variant="outlined"
                  size="small"
                  value={ state.organization }
                  onChange={ handleTextChange.bind(this, 'organization') }
                />
              </FormControl>
              <FormControl fullWidth className={ `${styles.formControl} ${styles.mb} ${styles.textArea}` }>
                <TextField
                  id="motivation-field"
                  label={ formatMessage('mapbiomas.user.create_account.fields.motivation') }
                  multiline
                  type="text"
                  variant="outlined"
                  size="small"
                  value={ state.motivation }
                  onChange={ handleTextChange.bind(this, 'motivation') }
                />
              </FormControl>
              {/* <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="#16a085" className={styles.privacyCheck} checked={state.privacyCheck} />}
                  onChange={ handlePrivacyCheckChange }
                  label={
                    <div className={styles.privacyLabel}>
                      <span>{intl.formatMessage({id: 'mapbiomas.user.create_account.privacy_policy.text'})}</span>
                      <Link className={styles.privacyLink} to={`${intl.formatMessage({id: 'mapbiomas.user.create_account.privacy_policy.link'})}`}>
                        {intl.formatMessage({id: 'mapbiomas.user.create_account.privacy_policy.title'})}
                      </Link>
                    </div>
                  }
                />
              </FormGroup> */}
              <div className={ styles.actionsWrapper }>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  className={styles.button}
                  disabled={!state.privacyCheck}
                >
                  <FormattedMessage id="mapbiomas.user.create_account.title" />
                </Button>
              </div>
            </form>
            <Typography className={styles.createAcountLegend}><FormattedMessage id="mapbiomas.user.login.helper_link.description" /></Typography>
            <Link className={ styles.helperLink } to={`/login?${urlParams}`}><FormattedMessage id="mapbiomas.user.login.helper_link.title" /></Link>
          </div>
          <FormFooter />
        </Paper>
      </div>
      <PageFooter />
    </div>
  );
}
