import { Box } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import styles from './FormFooter.module.scss'

export default function FormFooter() {
  const intl = useIntl()
  return(
    <Box className={ styles.wrapper }>
      <a className={ styles.helperLink } href="https://mapbiomas.org/"><FormattedMessage id="mapbiomas.user.login.footer.links.mapbiomas" /></a>
      {/* <Link className={ styles.helperLink } to="/esqueci-minha-senha"><FormattedMessage id="mapbiomas.user.login.footer.links.help" /></Link>
      <a className={ styles.helperLink } href={ intl.formatMessage({id:'mapbiomas.user.login.create_acount.privacy_policy.link'}) } target="_blank"><FormattedMessage id="mapbiomas.user.login.footer.links.privacy_term" /></a> */}
    </Box>
  )
}