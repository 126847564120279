import { gql } from 'graphql.macro'

export const GET_COMPONENT_INITIALIZATION_DATA = gql`
  query {
    app @client {
      baseParams {
        activeObjectTreeNodeIds
      }
    }
  }
`

export const GET_CLASSES_LEVELS_LIST = gql`
  query GetModuleClasses($moduleId: Int, $submoduleKey: String!) {
    submoduleByModuleIdAndKey(moduleId: $moduleId, key: $submoduleKey) {
      id
      key
      submoduleTrees {
        classTree {
          key
          i18nStrings {
						language
						stringValue
          }
          mvClassTreeLevelsUi {
            id
            color
            classId
            childrenIds
            positionInTree
            level
            parentId
            defaultName
            i18nStrings {
							language
							stringValue
            }
            classTreeNode {
              classe {
                classesTooltips {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`
