import React from 'react';
import classnames from 'classnames';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './MapboxControl.module.scss';

const useStyles = makeStyles((theme) => ({
  searchButtonActive: {
    color: `${ theme.palette.primary.main } !important`,
    backgroundColor: '#F1F1F1 !important',
  }
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

export default function MapboxControl({
  isActive,
  onToggle = () => {},
  headerIsVisible,
  showBeforeAndAfterMosaic,
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperHeader]: headerIsVisible,
      [styles.wrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
    }) }>
      <div id="3d-control" className={ styles.controlWrapper }>
        <CustomTooltip arrow title={ formatMessage('map_controls.view_3d.title') } placement="right">
          <button
            className={ classnames(styles.searchButton, {
              [classes.searchButtonActive]: isActive
            }) }
            onClick={ onToggle }
          >
            3D
          </button>
        </CustomTooltip>
      </div>
    </div>
  );
}
