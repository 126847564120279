import React from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { onCurrentLocaleCustom } from '../../../../../../../../utils/locale'
import numbro from 'numbro'

const getClassAreaForYear = _.memoize((year) => _.flow(
  _.property('classTreeNode.coverageClassificationDataByTreeList'),
  _.partial(_.filter, _, { year: year }),
  _.partial(_.map, _, 'areaInHa'),
  _.sum
))

const getPositionsToOffsetForIndex = (index, numberOfCharts) => {
  const horizontal = { 0: 'left', 1: 'right' }
  const vertical = { 0: 'bottom', 1: 'top' }
  const div = _.flow(_.divide, _.floor)
  const mod = (x, y) => x - (y * div(x, y))

  let positions = [ horizontal[mod(index, 2)] ]
  if (numberOfCharts > 2) {
    positions.push(vertical[div(index, 2)])
  }

  return positions
}

export default function ClassificationsChart({
  // Temporary workaround while this component isn't refactored into separate
  // non-grid based chart components
  allClassTreeLevels = [], // <---
  activeClassTreeLevels = [],
  classTreeCharts = [],
  years = null,
  activeYear
}) {
  if (!classTreeCharts || !years || !activeYear) {
    return null
  }

  const tooltipFormatter = (params) => {
    const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`

    const year = _.get(params, `[0].value[${_.indexOf(_.get(params, '[0].dimensionNames'), 'years')}]`, activeYear)
    const chartType = _.get(params, 'componentSubType', _.get(params, '[0].componentSubType'))

    if (chartType === 'pie') {
      const value = numbro(_.get(params, `data[${ _.get(params, 'encode.value[0]') }]`)).format({
        thousandSeparated: true,
        mantissa: 0
      })

      const percent = numbro(params.percent / 100).format({
        output: 'percent',
        mantissa: 2
      })

      return `
        <span>${_.get(params, 'seriesName')}</span>
        <span>${ colorSpan(params.color) } ${ _.get(params, 'name') }: ${ value } ha (${ percent })</span>
      `
    } else {
      return `<span>${ year }</span> \n` +
        _.map(
          activeClassTreeLevels,
          (node) => `
            <span>
              ${ colorSpan(node.color) }
              ${ node.positionInTree.join('.') }. ${ onCurrentLocaleCustom(node.i18nStrings) }:
                ${ numbro(
                  _.sum(_.map(_.filter(
                    _.get(node, 'classTreeNode.coverageClassificationDataByTreeList'),
                    { year: year }
                  ), 'areaInHa', 0)
                )).format({
                  thousandSeparated: true,
                  mantissa: 0
                })
              } ha
            </span>
          `
        ).join('\n')
    }
  }

  const generateBaseSerieForAnyChart = (chart) => ({
    type: _.get(chart, 'chartType.key')
  })

  // ugly workaround to postpone a little some refactor that will be needed
  // because I didn't make the best design choices in this component
  let levelsToShowOnPieChart = _.filter(allClassTreeLevels, { level: 1 })
  if (levelsToShowOnPieChart.length === 1) {
    levelsToShowOnPieChart = _.filter(allClassTreeLevels, { level: 2 })
  }

  const generateSerieForSingleYearChart = (chart) => (
    _.assign(generateBaseSerieForAnyChart(chart), {
      id: `serie#nodeId/year(${activeYear})`,
      name: `${activeYear}`,
      itemStyle: {
        color: ({ dataIndex }) => (
          _.get(levelsToShowOnPieChart, `[${dataIndex}].color`)
        )
      },
      label: {
        show: false
      },
      encode: {
        itemName: 'treeNodeNames',
        value: `year#${activeYear}`
      },
      left: '15',
      right: '15',
      ...(_.zipObject(
        getPositionsToOffsetForIndex(
          _.findIndex(classTreeCharts, { id: chart.id }),
          classTreeCharts.length
        ),
        ['55%', '55%']
      )),
    })
  )

  const generateSeriesForMultiYearChart = (chart, chartIndex) => _.map(activeClassTreeLevels, (treeNode) => (
    _.assign(generateBaseSerieForAnyChart(chart), {
      id: `serie#nodeId/year(${treeNode.id})`,
      name: onCurrentLocaleCustom(_.get(treeNode, 'i18nStrings')),
      itemStyle: { color: treeNode.color },
      encode: { x: 'years', y: `treeNodeId#${treeNode.id}` },
      xAxisIndex: chartIndex,
      yAxisIndex: chartIndex
    })
  ))

  const optionTransforms = {
    hideAxis(option, chart) {
      _.each(['x', 'y'], (axis) => {
        _.set(
          _.find(option[`${axis}Axis`], { id: `${axis}Axis#chartId(${chart.id})` }),
          'show', false
        )
      })
    },
    swapAxis(option, chart) {
      const oldXAxis = _.first(_.remove(option.xAxis, { id: `xAxis#chartId(${chart.id})` }))
      const oldYAxis = _.first(_.remove(option.yAxis, { id: `yAxis#chartId(${chart.id})` }))
      const oldXAxisId = oldXAxis.id
      oldXAxis.id = oldYAxis.id
      oldYAxis.id = oldXAxisId
      option.xAxis.push(oldYAxis)
      option.yAxis.push(oldXAxis)

      _.each(_.filter(option.series, { id: `serie#chartId(${chart.id})` }), (serie) => {
        const tempEncodeX = serie.encode.x
        serie.encode.x = serie.encode.y
        serie.encode.y = tempEncodeX
      })
    },
    parseStackedBar(option) {
      _.each(option.series, (serie) => {
        if (serie.type === 'stacked_bar') {
          serie.type = 'bar';
          serie.stack = 'years';
        }
      });
    }
  }

  const chartTypeTransformationMappings = {
    sunburst: ['putLevelsAsChildrenOnData'],
    pie: ['hideAxis'],
    bar: ['swapAxis'],
    stacked_bar: ['parseStackedBar'],
    line: [],
    area: [],
    stacked_area: [],
    column: [],
    stacked_column: []
  }

  const dataset = {
    sourceHeader: false,
    source: {
      years,
      treeNodeNames: _.map(levelsToShowOnPieChart, _.flow(_.property('i18nStrings'), onCurrentLocaleCustom)),

      ...(_.zipObject(
        _.map(activeClassTreeLevels, (n) => `treeNodeId#${n.id}`),
        _.map(activeClassTreeLevels, _.over(_.map(years, getClassAreaForYear)))
      )),

      ...(_.zipObject(
        _.map(years, (y) => `year#${y}`),
        _.map(years, (y) => _.map(levelsToShowOnPieChart, getClassAreaForYear(y)))
      ))
    }
  }

  let option = {
    legend: {
      show: false
    },
    tooltip: {
      confine: true,
      textStyle: {
        align: 'left',
        color: '#444',
        fontSize: 13
      },
      backgroundColor: '#FFFFFF',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: 1,
      extraCssText: 'box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);',
      formatter: tooltipFormatter
    },
    toolbox: {
      feature: {
        saveAsImage: {
          name: 'MapBiomas',
          title: 'Download',
        }
      }
    },

    dataset,
    grid: _.map(classTreeCharts, (chart, index) => ({
      id: `chart#${chart.id}`,
      tooltip: {
        trigger: chart.singleYear ? 'item' : 'axis'
      },
      left: '15',
      right: '15',
      ...(_.zipObject(getPositionsToOffsetForIndex(index, classTreeCharts.length), ['55%', '55%']))
    })),
    xAxis: _.map(classTreeCharts, (chart, index) => ({
      id: `xAxis#chartId(${chart.id})`,
      type: 'category',
      gridIndex: index
    })),
    yAxis: _.map(classTreeCharts, (chart, index) => ({
      id: `yAxis#chartId(${chart.id})`,
      gridIndex: index
    })),
    series: _.flatMap(classTreeCharts, _.cond([
      [_.matches({ chartType: { singleYear: true }}), generateSerieForSingleYearChart],
      [_.constant(true), generateSeriesForMultiYearChart]
    ]))
  }

  _.each(classTreeCharts, (chart) => {
    _.each(chartTypeTransformationMappings[chart.chartType.key], (transformation) => {
      optionTransforms[transformation](option, chart)
    })
  })

  return <ReactEcharts
    option={ option }
    style={{
      width: '100%',
      height: (((_.floor((classTreeCharts.length - 1) / 2) % 2) + 1) * 380)
    }}
  />
}
